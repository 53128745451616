/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, CircularProgress, FormControl, MenuItem, Select } from '@mui/material'
import Topbar from '../../components/common/topbar/Topbar'
import DataTable from '../../components/common/datatable/Datatable'
import { useEffect, useState } from 'react'
import NewsanIcons from '../../components/common/Icons'
import classes from './WarehousesTypes.module.css'
import {
  FilterTable,
  FilterTableType,
  TableAction,
  TableDetailCell,
  TableHeaderCell,
  WarehouseType,
} from '../../model/models-module'
import CloseIcon from '@mui/icons-material/Close'
import { useData } from '../../components/hooks/useData'
import axios from 'axios'
import AxiosInterceptor from '../../services/http/AxiosInterceptor'
import PopupSuccessError from '../../components/common/popup-success-error/PopupSuccessError'
import EditIcon from '@mui/icons-material/Edit'
import { GridDTO } from '../../model/dto-module'
import { addParam } from '../../utils/CommonUtils'

type typeBody = {
  description?: string
  code?: string
  status?: boolean
}

const client = AxiosInterceptor.addInterceptor(process.env.REACT_APP_API_BASE_URL)

const WarehousesTypes = () => {
  const [dataShow, setDataShow] = useState<any>([])
  const [dataTo, setDataTo] = useState<number>(20)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [showForm, setShowForm] = useState<boolean>(false)
  const [newEditItem, setNewEditItem] = useState<WarehouseType | undefined>()
  const [countItems, setCountItems] = useState<number>(0)
  const [dataOffset, setDataOffset] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [baseEndpoint, setBaseEndpoint] = useState<string>('/warehouses-types')
  const [endpoint, setEndpoint] = useState<string>('')
  const [loadingWarehouseType, errorWarehouseType, dataWarehouseType] = useData<
    GridDTO<WarehouseType>
  >(endpoint, 'GET')

  const [selectedDescription, setSelectedDescription] = useState<string>('')
  const [selectedCode, setSelectedCode] = useState<string>('')
  const [selectedEstado, setSelectedEstado] = useState<boolean>()

  const [sortArg, setSortArg] = useState<string>('')
  const [newSort, setNewSort] = useState<number>(0)
  const [initialized, setInitialized] = useState<boolean>(false)
  const [sort] = useState<Map<string, string>>(new Map())
  const [params] = useState<Map<string, string>>(new Map())
  const [loadingResponse, setLoadingResponse] = useState(false)
  const [errorResponse, setErrorResponse] = useState<string[] | null>(null)
  const [dataResponse, setDataResponse] = useState<string | null>(null)
  const [descriptionFilter, setDescriptionFilter] = useState<string>()
  const [statusFilter, setStatusFilter] = useState<string>()

  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [dataHeader, setDataHeader] = useState<TableHeaderCell[]>([])

  useEffect(() => {
    if (!initialized) {
      const isNotLoading = !loadingWarehouseType
      const hasData = dataWarehouseType != null
      setInitialized(isNotLoading && hasData)
      setDataTo(20)
    }
  }, [loadingWarehouseType])

  useEffect(() => {
    addParam(dataTo, 'limit', params, baseEndpoint, setEndpoint)
  }, [dataTo, baseEndpoint])

  useEffect(() => {
    if (initialized) {
      addParam(dataOffset, 'offset', params, baseEndpoint, setEndpoint)
    }
  }, [dataOffset, baseEndpoint])

  useEffect(() => {
    if (initialized) {
      setDataOffset(0)
      setDataShow([])
      addParam(sortArg, 'sort', params, baseEndpoint, setEndpoint)
    }
  }, [sortArg, baseEndpoint])

  useEffect(() => {
    if (initialized) {
      addParam(descriptionFilter, 'description', params, baseEndpoint, setEndpoint)
    }
  }, [descriptionFilter, baseEndpoint])

  useEffect(() => {
    if (initialized) {
      addParam(statusFilter, 'status', params, baseEndpoint, setEndpoint)
    }
  }, [statusFilter, baseEndpoint])

  const fetchData = async (body?: typeBody) => {
    try {
      setLoadingResponse(true)
      if (isEdit) {
        await client.patch(`/warehouses-types/${newEditItem?.id}`, body)
        setDataResponse('Modificado con éxito!')
        setErrorResponse(null)
        setIsEdit(false)
        setNewEditItem(undefined)
      } else {
        await client.post('/warehouses-types', body)
        setDataResponse('Creado con éxito')
        setErrorResponse(null)
      }
      formatForm()
      setShowForm(false)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setErrorResponse(error.response?.data.message)
      } else if (error instanceof Error) {
        setErrorResponse([error.message])
      } else {
        setErrorResponse(['Hubo un error en la solicitud'])
      }
    } finally {
      setLoadingResponse(false)
    }
  }

  const formatForm = () => {
    setSelectedCode('')
    setSelectedDescription('')
    setSelectedEstado(undefined)
  }

  const saveFormInfo = () => {
    // Validar campos del formulario
    if (!selectedCode || !selectedDescription || selectedEstado == null || undefined) {
      alert('Se deben completar todos los campos')
    } else {
      const temporalObject: typeBody = {
        description: selectedDescription,
        code: selectedCode,
        status: selectedEstado,
      }
      setShowPopup(true)
      fetchData(temporalObject)
    }
  }

  const deleteTemplateConfig = async (id: number) => {
    try {
      setLoadingResponse(true)
      await client.delete(`/warehouses-types/${id}`)
      setDataResponse('Eliminado con éxito!')
      setErrorResponse(null)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setErrorResponse(error.response?.data.message)
      } else if (error instanceof Error) {
        setErrorResponse([error.message])
      } else {
        setErrorResponse(['Hubo un error en la solicitud'])
      }
    } finally {
      setLoadingResponse(false)
    }
  }

  useEffect(() => {
    if (baseEndpoint == '') setBaseEndpoint('/warehouses-types')

    if (!errorWarehouseType && dataWarehouseType) {
      setCountItems(dataWarehouseType.count)
      setDataShow(
        dataWarehouseType.results.map((r: WarehouseType) => {
          const actions: TableAction[] = []
            actions.push(
              new TableAction({
                label: 'Editar',
                action: () => {
                  formatForm()
                  setNewEditItem(r)
                  setIsEdit(true)
                  setShowForm(true)
                },
                icon: (
                  <span className={classes.editIcon}>
                    <EditIcon></EditIcon>
                  </span>
                ),
              }),
            )
            actions.push(
              new TableAction({
                label: 'Eliminar',
                action: async () => {
                  setShowPopup(true)
                  await deleteTemplateConfig(r.id)
                },
                icon: NewsanIcons.DELETE,
              }),
            )
          return new TableDetailCell(
            { description: r.description, status: r.status ? 'Activa' : 'Inactiva' },
            actions,
            undefined,
            classes['storesDataTable'],
          )
        }),
      )
    }
  }, [setDataShow, baseEndpoint, dataWarehouseType])

  useEffect(() => {
    const filterLoadeed =
      !loadingWarehouseType && dataWarehouseType != null && dataHeader.length === 0

    if (filterLoadeed) {
      setDataHeader([
        new TableHeaderCell({
          label: 'Descripción',
          key: 'description',
          sorteable: true,
          filter: new FilterTable({
            onChange: (newVal) => {
              setDescriptionFilter(newVal)
            },
            placeholder: 'Busca aqui...',
          }),
        }),
        new TableHeaderCell({
          label: 'Estado',
          key: 'status',
          sorteable: true,
          filter: new FilterTable({
            onChange: (newVal) => {
              setStatusFilter(newVal)
            },
            type: FilterTableType.COMBO,
            data: [
              { id: 0, code: 'false', description: 'Inactiva' },
              { id: 1, code: 'true', description: 'Activa' },
            ],
            useCodeField: true,
          }),
        }),
      ])
    }
  })

  useEffect(() => {
    if (newEditItem && isEdit) {
      // Setear campos del formulario con los valores del item a editar
      setSelectedDescription(newEditItem.description)
      setSelectedCode(newEditItem.code)
      setSelectedEstado(newEditItem.status)
    }
  }, [loadingWarehouseType, newEditItem, isEdit])

  useEffect(() => {
    if (newSort > 0) {
      let args = ''
      sort.forEach((value) => {
        if (args === '') {
          args += value
        } else {
          args += `,${value}`
        }
      })
      setSortArg(args)
      setNewSort(0)
    }
  }, [newSort, setSortArg, setNewSort])

  const onSort = (key: string, order: string) => {
    if (order === '') {
      sort.delete(key)
    } else {
      sort.set(key, `${key}:${order}`)
    }
    setNewSort(1)
  }

  function reloadTable() {
    setBaseEndpoint('')
    setEndpoint('')
  }

  return (
    <>
      {loadingWarehouseType ? (
        <div className='loadingPage'>
          <CircularProgress className='circleProgress' />
        </div>
      ) : null}
      <div className={classes.warehouseTypePage}>
        <Topbar title={'Tipos de almacén'} hasReloadIcon reloadPage={reloadTable}/>
        <div className={classes['container-abm-page']}>
          {errorWarehouseType ? (
            <>Ha habido un problema!</>
          ) : (
            <div
              className={
                showForm
                  ? classes['table-templates-container-form-visible']
                  : classes['table-templates-container']
              }
            >
              <DataTable
                headers={dataHeader}
                rows={dataShow}
                countItems={countItems}
                rowsPerPage={dataTo}
                hasActions={true}
                appendOnScrollToBottom={false}
                onPageSizeChange={(size) => {
                  setDataTo(size)
                  setDataOffset(0)
                  setPage(1)
                }}
                onPageChange={(page: number) => {
                  setPage(page)
                  setDataOffset(page - 1)
                }}
                pageSelected={page}
                pageable={true}
                selectable={false}
                onSortChange={onSort}
                className={classes.warehouseTypeDataTable}
              ></DataTable>
            </div>
          )}
            {initialized && showForm ? (
              <div className={classes['abm-container']}>
                <div
                  className={
                    isEdit ? classes['edit-form-container'] : classes['add-form-container']
                  }
                >
                  <span className={classes['title-form']}>
                    {NewsanIcons.ADD_STORE}{' '}
                    <span className={classes['title-form-text']}>
                      {isEdit ? 'Editar' : 'Agregar'}{' '}
                    </span>
                  </span>
                  <CloseIcon
                    className={classes['form-close-button']}
                    onClick={() => {
                      formatForm()
                      setShowForm(false)
                      setIsEdit(false)
                      setNewEditItem(undefined)
                    }}
                  ></CloseIcon>
                  <span className={classes['setting-attr-title']}>Nombre del Tipo de almacén*</span>
                  <input
                    type='text'
                    className={classes['setting-attr-input']}
                    placeholder='Nombre del Tipo de almacén'
                    defaultValue={selectedDescription}
                    onChange={(e) => {
                      setSelectedDescription(e.target.value)
                    }}
                    onInput={(e) => {
                      const value = (e.target as HTMLInputElement).value
                      if (value == '') setSelectedDescription(value)
                    }}
                  />

                  <span className={classes['setting-attr-title']}>Código del Tipo de almacén*</span>
                  <input
                    type='text'
                    className={classes['setting-attr-input']}
                    placeholder='Código del Tipo de almacén'
                    defaultValue={selectedCode}
                    onChange={(e) => {
                      setSelectedCode(e.target.value)
                    }}
                    onInput={(e) => {
                      const value = (e.target as HTMLInputElement).value
                      if (value == '') setSelectedCode(value)
                    }}
                  />

                  <span className={classes['setting-attr-title']}>Estado*</span>
                  <FormControl className={classes['select-filter']} size='small'>
                    <Select
                      value={selectedEstado ? 'Activa' : selectedEstado == false ? 'Inactiva' : ''}
                      onChange={(e) => {
                        if (e.target.value == 'Activa') {
                          setSelectedEstado(true)
                        } else if (e.target.value == 'Inactiva') {
                          setSelectedEstado(false)
                        }
                      }}
                    >
                      <MenuItem value={''}>
                        <em className={classes['no-selection']}>Seleccionar</em>
                      </MenuItem>
                      <MenuItem
                        className={classes['select-option']}
                        key={'Activa'}
                        value={'Activa'}
                      >
                        Activa
                      </MenuItem>
                      <MenuItem
                        className={classes['select-option']}
                        key={'Inactiva'}
                        value={'Inactiva'}
                      >
                        Inactiva
                      </MenuItem>
                    </Select>
                  </FormControl>

                  <div className={classes['buttons-container']}>
                    <Button
                      variant='contained'
                      className={classes['btn-newsan-cancel']}
                      onClick={() => {
                        setShowForm(false)
                        setIsEdit(false)
                        setNewEditItem(undefined)
                        setDataResponse(null)
                        setErrorResponse(null)
                        formatForm()
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant='contained'
                      onClick={async () => {
                        await saveFormInfo()
                      }}
                      className={
                        isEdit ? classes['button-newsan-red'] : classes['button-newsan-grey']
                      }
                    >
                      {isEdit ? 'Guardar' : 'Cargar'}
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <span className={classes['btn-show-form']} onClick={() => setShowForm(!showForm)}>
                {NewsanIcons.ADD_STORE}
              </span>
            )}
        </div>
        {showPopup ? (
          <PopupSuccessError
            open={showPopup}
            onClose={() => {
              setShowPopup(false)
              setDataResponse(null)
              setBaseEndpoint(errorResponse == null ? '' : baseEndpoint)
              setErrorResponse(null)
            }}
            loading={loadingResponse}
            success={dataResponse !== null}
            successMessage={dataResponse}
            errorMessage={errorResponse}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default WarehousesTypes
