/* eslint-disable */

import { Outlet } from 'react-router-dom'
import classes from './AvailableStock.module.css'
import DataTable from '../../components/common/datatable/Datatable'
import { TableDetailCell, TableHeaderCell } from '../../model/models-module'
import { useEffect, useState } from 'react'
import Topbar from '../../components/common/topbar/Topbar'
import { useData } from '../../components/hooks/useData'
import { Box, Button, CircularProgress, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, SvgIcon, TextField, Tooltip, Typography } from '@mui/material'
import { addParam } from '../../utils/CommonUtils'
import NewsanIcons from '../../components/common/Icons'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import AvailableStockService from '../../services/AvailableStockService'
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment'
import { useDownloadContext } from '../../providers/DownloadContext'
import { toast, ToastContainer } from 'react-toastify'
import NewsanPics from '../../components/common/Images'

export interface AvailableStock {
    type: string,
    id: number,
    attributes: {
        type: string,
        sku: string,
        description: string,
        organizationCode: string,
        availableToReserve: number
    }
}

const AvailableStock = () => {
    const service: AvailableStockService = AvailableStockService.Instance;
    const {pendingDownloads, addDownload, removeDownload } = useDownloadContext();
    const [dataShow, setDataShow] = useState<TableDetailCell[]>([])
    const [sku, setSku] = useState<string>('')
    const [skuSearch, setSkuSearch] = useState<string>('')
    const [params] = useState<Map<string,string>>(new Map());  
    const [dataHeader, setDataHeader] = useState<TableHeaderCell[]>([])
    const [baseEndpoint, setBaseEndpoint] = useState<string>('stocks/distribution-centres');
    const [endpoint, setEndpoint] = useState<string>('')
    const [loadingDatagrid, errorDatagrid, dataGrid] = useData<AvailableStock[]>(
        skuSearch != '' && baseEndpoint != '' ? endpoint : '',
        'GET',
    )
    const [showTable, setShowTable] = useState<boolean>(false)
    const [showSearchBySky, setShowSearchBySky] = useState<boolean>(true)
    const [showMassiveDownload, setShowMassiveDownload] = useState<boolean>(true)
    const [productTitle, setProductTitle] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if (!loadingDatagrid && dataGrid != null && dataGrid.length > 0 && dataHeader.length === 0 && !errorDatagrid) {
            setDataHeader([
                new TableHeaderCell({
                    label: 'CD',
                    key: 'organizationCode',
                    sorteable: true,
                    align: 'center',
                    colStyle: { position: 'relative', top: '5px', left: '4px', textAlign: 'center'},
                }),
                new TableHeaderCell({ label: 'Disponible', key: 'availableToReserve', sorteable: true, align: 'center', colStyle: {position: 'relative', top: '5px', left: '4px', textAlign: 'center'}})
            ])
            const filteredData = dataGrid
                .map((r) =>
                    r.attributes.availableToReserve > 0
                        ? new TableDetailCell(r?.attributes, [])
                        : undefined
                )
                .filter((item): item is TableDetailCell => item !== undefined);
            setDataShow(filteredData);
            if (dataGrid[0].attributes.description) {
                setProductTitle(dataGrid[0].attributes.description)
                setShowTable(true)
            }
        }

    },[dataGrid, errorDatagrid, loadingDatagrid])


    useEffect(()=> {
        setDataShow([]);
        addParam(skuSearch, 'sku', params, baseEndpoint, setEndpoint);
    }, [skuSearch, baseEndpoint])

    const onSort = (key: 'availableToReserve' | 'organizationCode', order: string) => {
        if (order === '') {
            setDataShow([])
        } else {
            dataGrid?.sort((a, b) => {
                const aValue = a.attributes[key];
                const bValue = b.attributes[key];
    
                if (order === 'ASC') {
                    return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
                } else {
                    return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
                }
            });
        }
    };

    async function reloadTable(){
        formatTable()
        await setBaseEndpoint('')
        setBaseEndpoint('stocks/distribution-centres')
    }

    function formatTable(){
        setShowTable(false)
        setDataShow([]);
        setSkuSearch('');
        setProductTitle('')
        setDataHeader([])
    }

    const handleChange = (event: any) => {
        setSku(event.target.value);
        if(event.target.value != skuSearch) {
            setShowTable(false)
            setProductTitle('')
        }
    };
    
    useEffect(() => {
        if (errorDatagrid) {
            console.error(errorDatagrid);
            toast.error(errorDatagrid[0], {
                position: "top-right",
                autoClose: 5000, // Duración del toast
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    
    }, [errorDatagrid])

    const downloadMassiveXlsx = () => {
        addDownload()
        service.generateMassiveReport().then(
        (data: any)=>{
            const blob = new Blob([data]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `disponible_${moment(new Date()).format('YYYYMMDDHHmm')}.xlsx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            removeDownload()
        }
        ).catch(
            ()=> {
                toast.error("Error al descargar el reporte");
                removeDownload()
            }
        ).finally(
        () => {
            setLoading(false);
        }
        );
    }

    return (
        <div className={classes.availableStockPage}>
            {
            loadingDatagrid || loading ?
            <div className='loadingPage'>
                <CircularProgress className='circleProgress'/>
            </div>
            : null}
            <Topbar title={'Reporte de disponible'} hasReloadIcon reloadPage={reloadTable} />
            <div style={{display: 'flex'}}>
                <div className={classes['containers']}>
                    <Box sx={{ display: 'flex', alignItems: 'center'}}>
                        <div className='topbar-icon-section' style={{cursor: 'pointer'}} onClick={() => setShowSearchBySky(!showSearchBySky)}>
                            {showSearchBySky ? <KeyboardArrowDownIcon fontSize='large'></KeyboardArrowDownIcon> : <NavigateNextIcon fontSize='large'></NavigateNextIcon>}
                        </div>
                        <Typography className={classes.titleClass}>Consulta por SKU</Typography >
                    </Box>
                    {showSearchBySky ? 
                        <div className={classes['container-abm-page']}>
                            <Grid container marginLeft={'10px'}>
                                <Grid item sx={{display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}>
                                    <TextField
                                        label="SKU"
                                        value={sku}
                                        onChange={handleChange}
                                        fullWidth
                                        sx={{
                                            minWidth: '20vw',
                                            width: '20vw',
                                            height: '41px',
                                            '& .MuiInputLabel-root': {
                                                color: '#721c31',
                                                bottom: '26px',
                                                top: 'auto',
                                                '&.Mui-focused': {
                                                    color: '#721c31',
                                                },
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '10px',
                                                height: '40px',
                                                '& fieldset': {
                                                    border: '2px solid lightgray',
                                                },
                                                '&:hover fieldset': {
                                                    border: '2px solid gray',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    border: '2px solid #721c31',
                                                },
                                            },
                                        }}
                                        InputProps={{
                                            endAdornment: sku && (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => {
                                                        setSku('')
                                                    }} edge="end">
                                                        <ClearIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Button
                                    variant='contained'
                                    className={classes['button-newsan-grey']}
                                    onClick={async () =>{
                                        await formatTable();
                                        setSkuSearch(sku);
                                    }}
                                    disabled={!sku}
                                    >
                                        <span className={classes.searchIcon}>{NewsanIcons.SEARCH}</span> Buscar
                                    </Button>
                                </Grid>
                            </Grid>
                            {productTitle ? <Typography className={classes.productDescription} width={'auto'}>{productTitle}</Typography > : null}
                            {showTable ? <div className={classes['table-templates-container']}>
                                {dataShow.length == 0 && baseEndpoint != '' ? <Typography sx={{margin: '8px'}}>No hay stock disponible</Typography > : <DataTable
                                    headers={dataHeader}
                                    rows={dataShow}
                                    appendOnScrollToBottom={false}
                                    pageable={false}
                                    className={classes.dataTableStock}
                                    onSortChange={onSort}
                                ></DataTable>}
                            </div>  : null}
                    </div> : null}
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '4vh'}}>
                        <div className='topbar-icon-section' style={{cursor: 'pointer'}} onClick={() => setShowMassiveDownload(!showMassiveDownload)}>
                            {showMassiveDownload ? <KeyboardArrowDownIcon fontSize='large'></KeyboardArrowDownIcon> : <NavigateNextIcon fontSize='large'></NavigateNextIcon>}
                        </div>
                        <Typography className={classes.titleClass}>Consulta masiva</Typography >
                    </Box>
                    {showMassiveDownload ? 
                        <div className={classes['massive-section']}>
                            <Grid container marginLeft={'10px'} 
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                <Typography sx={{
                                    width: '68%',
                                    fontSize: '0.87rem',
                                    fontFamily:' Gilroy-Bold',
                                    color: 'var(--secondary-color)',
                                    border: '2px solid rgba(128, 128, 128, 0.282)',
                                    borderRadius: '10px',
                                    padding: '12px 2px 12px 12px'
                                }}>Al generar el reporte, se descargará un archivo Excel que detalla los SKU de tipo físico y lógico, junto con la información actualizada de su stock disponible.</Typography>
                                <Button variant="contained" className={classes['button-newsan-red']} onClick={downloadMassiveXlsx}>
                                    <FileDownloadOutlinedIcon />
                                    Generar reporte
                                </Button>
                            </Grid>
                            
                    </div> : null}
                </div>
                <span className={classes.character}>
                    {NewsanPics.AVAILABLE_CHARACTER}
                </span>
            </div>
            
        <Outlet />
        </div>
    )
}

export default AvailableStock
