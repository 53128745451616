import { ReactNode, useEffect, useState } from 'react'
import { AppBar, Badge, Button, CircularProgress, Toolbar, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import colorConfigs from '../../../configs/colorConfigs'
import sizeConfigs from '../../../configs/sizeConfigs'
import UserService from '../../../services/UserService'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import MenuIcon from '@mui/icons-material/Menu';
import InputBase from '@mui/material/InputBase';
import NewsanIcons from '../Icons';
import { useNavigate } from 'react-router-dom'
import NotificationCard from './notification-card/NotificationCard'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import classes from './Topbar.module.css'
import { Notification, NotificationResponse } from '../../../model/models-module'
import { useData } from '../../hooks/useData'
import { addParam } from '../../../utils/CommonUtils'
import { useDownloadContext } from '../../../providers/DownloadContext'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { styled, keyframes } from '@mui/system';

type Props = {
  state?: string;
  title?: string;
  subtitle?: string;
  backButton?: boolean;
  children?: ReactNode;
  showSearch?: boolean;
  onGoBack?():void;
  hasReloadIcon?: boolean;
  reloadPage?(): void;
}

const userImage = 'https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg';
const userHasImage = false;

const Topbar = ({ title, subtitle, children, backButton = false, showSearch=false, onGoBack, hasReloadIcon=false, reloadPage}: Props) => {
  const { pendingDownloads } = useDownloadContext();
  const [username, setUsername] = useState<string>(UserService.getUsername());
  const [email] = useState<string>(UserService.getEmail());
  const [baseEndpoint, setBaseEndpoint] = useState<string>('/notifications/');  
  const [endpoint, setEndpoint] = useState<string>('');
  const [params] = useState<Map<string,string>>(new Map()); 
  const [loading, error, dataGrid] = useData<NotificationResponse>(endpoint, 'GET');
  const [countItems, setCountItems] = useState<number>(0);
  const [dataShow, setDataShow] = useState<Notification[]>([]);
  const [circleProgress, setCircleProgress] = useState<boolean>(false)
	const navigate = useNavigate();
  if(onGoBack === undefined) {
    onGoBack = ()=> navigate(-1);
  }
  if(error) {
    console.error(error);
  }

  const [goBack, setGoBack] = useState<boolean>(false);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const [anchorElNotif, setAnchorElNotif] = useState<null | HTMLElement>(null)

  const blinkAnimation = keyframes`
    0% { opacity: 1; }
    50% { opacity: 0.3; }
    100% { opacity: 1; }
  `;

  const BlinkingToolbar = styled(Toolbar)(() => ({
    animation: `${blinkAnimation} 1.5s infinite`,
  }));

  useEffect(() => {
    const interval = setInterval(() => {
      setBaseEndpoint('');
      setUsername('');
      setCountItems(0);
    }, 120000);
    return () => clearInterval(interval);
  }, [setBaseEndpoint, baseEndpoint, endpoint, username, countItems]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = (setting: string) => {
    setAnchorElUser(null)
    switch (setting) {
      case 'Logout':
        UserService.doLogout()
        break
      default:
        break
    }
  }

  const handleOpenNotifMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotif(event.currentTarget)
  }

  const handleCloseNotifMenu = () => {
    setAnchorElNotif(null)
  }

  const onClickNotificationRead = () => {
    setAnchorElNotif(null)
    const interval = setTimeout(() => {
      setBaseEndpoint('');
      setUsername('');
      setCountItems(0);
      setCircleProgress(true);
    }, 500);
    return () => clearInterval(interval);
  }

  useEffect(()=>{
    setBaseEndpoint('/notifications/');
    setUsername(UserService.getUsername());

    if(!loading && dataGrid) {
      setCircleProgress(false)
      setCountItems(dataGrid.count);
      dataGrid.results.sort((a, b) => {
        const fechaA = new Date(a.created!).getTime();
        const fechaB = new Date(b.created!).getTime();
        return fechaB - fechaA;
      });
      setDataShow(dataGrid.results)
    }
  }, [setDataShow, dataGrid, loading, baseEndpoint, username, countItems]);

  useEffect(()=> {
    addParam(username, 'user', params, baseEndpoint, setEndpoint);
  }, [username])

  useEffect(()=> {
    addParam('unread', 'status', params, baseEndpoint, setEndpoint);
  }, [])

  useEffect(()=>{
    if(goBack) {
      onGoBack!();
    }

  }, [goBack]);

  return (
    <AppBar
      position='relative'
      sx={{
        // width: `calc(100% - ${sizeConfigs.sidebar.width})`,
        width: '100%',
        maxWidth: 'unset',
        marginTop: '20px',
        height: sizeConfigs.topbar.heigth,
        boxShadow: 'unset',
        backgroundColor: colorConfigs.mainBg,
        color: colorConfigs.topbar.color,
        paddingLeft: '0px',
      }}
    >
      <Toolbar sx={
        {
          paddingLeft: '0px !important',
          paddingRight: '0px !important'          
        }
      }>
        <Container disableGutters maxWidth='xl' style={ {maxWidth: 'unset'} }>
          <Toolbar disableGutters sx={{
            justifyContent: 'space-between'
          }}>
            <Box sx={{ display: 'flex', paddingTop: '10px', paddingRight: '10px', alignItems: 'center'}}>
              <div className='topbar-icon-section' onClick={()=>{ if(backButton) { setGoBack(true); }}}>
                {
                backButton ?
                  <NavigateBeforeIcon fontSize='large' style={{cursor: 'pointer'}}></NavigateBeforeIcon>
                :
                  <NavigateNextIcon fontSize='large'></NavigateNextIcon>
                }
              </div>
              <div className='topbar-title-section'>
                <span className='topbar-title' style={{cursor: backButton ? 'pointer' : 'auto' }} onClick={()=>{ if(backButton) { setGoBack(true); }}}>{title}</span>
                <span className='topbar-subtitle'>{subtitle}</span>
              </div>
              {
                hasReloadIcon && reloadPage ? 
                  <i className={classes.refreshIconTitle}onClick={() => { reloadPage() }}>
                    {NewsanIcons.REFRESH_OUTLINED}
                  </i> 
                :
                null
              }
            </Box>
            <Box sx={{ width: `${showSearch ? 40 : 60}%`, minHeight: '42px', position: 'relative' }}>
              {children}
            </Box>
            <Box sx={{ width: `${showSearch ? 40 : 20}%`, textAlign: 'right', display: 'flex', justifyContent: 'flex-end'}}>
              {
                showSearch ? 
                  <Box className='search-container' 
                        component="form"
                        noValidate
                        autoComplete="off">

                      <IconButton sx={{ p: '10px' }} aria-label="menu" className='search-button'>
                        {NewsanIcons.SEARCH}
                      </IconButton>
                      <InputBase
                        sx={{ ml: 1, flex: 2, width: 'calc(100% - 100px)' }}
                        placeholder="Búscar"
                        inputProps={{ 'aria-label': 'buscar' }}
                      />
                      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <MenuIcon />
                      </IconButton>

                  </Box>
                : null
              }
                {pendingDownloads ? <BlinkingToolbar style={ {
                    paddingRight: '0px',
                    display: 'inline-block',
                  }}
                  >
                  <Tooltip title='Descargando...'>
                    <IconButton className={classes['button-newsan-red']} sx={{ p: 0 }}>
                      <FileDownloadOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </BlinkingToolbar>: null}
                <Badge badgeContent={countItems} color='error' style={{ paddingLeft: '24px'}}>
                  <IconButton className='notification-container' onClick={handleOpenNotifMenu} sx={{ p: 0 }} >
                  {
                    loading || circleProgress ?
                    <div style={{height: '100%', width: '100%', zIndex: '1201', marginTop: '10px'}}>
                      <CircularProgress size={20}/>
                    </div>
                    : NewsanIcons.NOTIFICATION}
                  </IconButton>
                </Badge>
              {username ? (
                <Toolbar style={ {
                paddingRight: '0px',
                display: 'inline-block',
                }}    
                >
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar style={{borderRadius: '10px'}} alt={username} src='/static/images/avatar/2.jpg' />
                  </IconButton>
                </Toolbar>
              ) : (
                <></>
              )}
              <Menu
                sx={{ mt: '45px' }}
                id='menu-appbar'
                className='menu-user'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <div className='user-info'>
                  <div className='profile-pic-container'>
                    <Avatar className='profile-pic' style={{borderRadius: '10px'}} alt={username} src={userHasImage ? userImage : '/static/images/avatar/2.jpg'} />
                  </div>
                  <div className='profile-detail-container'>
                    <span className='profile-detail-name'>{username}</span>
                    <span className='profile-detail-email'>{email}</span>
                    <span className='profile-detail-rol'>Rol:</span>

                  </div>
                </div>

                <div className='menu-user-buttons-container'>
                  {/* <Button variant="contained" className='button-newsan-grey'>{NewsanIcons.LOCK} Cambiar Contraseña</Button> */}
                  <Button variant="contained" className='button-newsan-red' onClick={() => handleCloseUserMenu('Logout')}><PowerSettingsNewIcon></PowerSettingsNewIcon> Cerrar Sesión</Button>

                </div>
                {/* {settings.map((setting) => (
                  <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                    <Typography textAlign='center'>{setting}</Typography>
                  </MenuItem>
                ))} */}
              </Menu>
            </Box>
                  <Menu
                  sx={{ mt: '45px', maxWidth: '30%' }}
                  id='menu-notifications'
                  className='menu-notification'
                  anchorEl={anchorElNotif}
                  style={{height: '32vw'}}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElNotif)}
                  onClose={handleCloseNotifMenu}
                >
                  <div className='content'>
                    {dataShow.map((n)=>{
                      let styleIcon = null;
                          if (n.criticality == 'high'){
                              styleIcon = 'iconRed';
                          }else if (n.criticality == 'medium'){
                              styleIcon = 'iconYellow'
                          } else if (n.criticality == 'low'){
                              styleIcon = 'iconBlue'
                          }

                      return (
                      <MenuItem key={n.id} onClick={() => handleCloseNotifMenu()} style={{paddingInline: '10px', cursor: 'auto'}}>
                        <NotificationCard id={n.id} title={n.title!} date={n.created!} type={n.type!} criticality={n.criticality!} message={n.description!} key={`noticard${n.id}`} iconColor={styleIcon} onClick={onClickNotificationRead}></NotificationCard>
                      </MenuItem>
                      );
                    })}
                  </div>
                  
                  <div className={classes['buttonContainer']}>
                    <Button className={classes['verTodasButton']} onClick={()=>navigate('/notifications')}>Ver todas {countItems != 0 ? '('+countItems+ ' no leídas)' : ''}</Button>
                  </div>
                  </Menu>
          </Toolbar>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

export default Topbar
