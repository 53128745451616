/* eslint-disable @typescript-eslint/no-explicit-any */
import { Menu, } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { CodeDescription, FilterTable, FilterTableType } from '../../../model/models-module';
import NewsanIcons from '../Icons';
import CheckboxList, { CheckboxValueField } from '../checkbox-list/CheckboxList';
import InputFilter from '../input-filter/InputFilter';
import InputDateRangeFilter from '../input-date-range-filter/InputDateRangeFilter';

const drawFilter = (onChange: (newVal:any)=>void, value:any, useCodeField: boolean, data?: CodeDescription[], type?: FilterTableType, placeholder?: string) => {
    switch(type) {
        case FilterTableType.COMBO:
            return <CheckboxList listOptions={data ?? []} setSelectedOptions= {onChange} multiple={false}  valueField={useCodeField ? CheckboxValueField.CODE : CheckboxValueField.ID} selectedOptions={ Array.isArray(value) ? value : []}></CheckboxList>;
        case FilterTableType.MULTIPLE:
            return <CheckboxList listOptions={data ?? []} setSelectedOptions= {onChange}  valueField={useCodeField ? CheckboxValueField.CODE : CheckboxValueField.ID} selectedOptions={Array.isArray(value) ? value : []}></CheckboxList>;
        case FilterTableType.TEXT:
            return <span className='custom-element filter-option-text'> <InputFilter type='text' onChange={onChange} value={value} placeholder={placeholder} showClear={true}></InputFilter> </span> 
        case FilterTableType.DATE:
            return <InputDateRangeFilter
                valueFrom={value.issueDateFrom}
                valueTo={value.issueDateTo}
                onChange={onChange}
            />
    }

}

const CheckIsActive = (value: any) => {
    const isArrayActive = Array.isArray(value) && value.length > 0;
    const isString = value!=null && value != undefined && value.toString().trim() != '' && !(value instanceof Object)
    const isDateObject = value.from != undefined || value.to != undefined
    
    return isArrayActive || isDateObject || isString
}  

const FilterOption = ({value, onChange, useCodeField = false, data, type, placeholder}: FilterTable) => {

    const [anchorElActions, setAnchorElActions] = useState<null | HTMLElement>(null);
    const [isActive, setIsActive] = useState<boolean>(CheckIsActive(value));
    const [filter, setFilter] = useState<any>();
    const [valueText] = useState<any>(value);

    const handleOpenActionMenu = (event: React.MouseEvent<HTMLElement>) => {
        if (anchorElActions !== event.currentTarget) {
            setAnchorElActions(event.currentTarget);
        }
    }

    const handleCloseActionMenu = () => {
        setAnchorElActions(null);
    }

    useEffect(()=>{
        setFilter( drawFilter(onChangeFn, valueText, useCodeField,  data, type, placeholder))
    }, [valueText]);

    const onChangeFn = useCallback((a: any)=>{
        setIsActive(CheckIsActive(a))
        onChange(a);}
    , []);

    return (
        <>
                <i className='filter-container' onClick={handleOpenActionMenu}>
                    {
                    isActive ? 
                    <span className='filter-icon-active'>
                        {NewsanIcons.FILTER_FILLED}
                    </span>
                    :
                    NewsanIcons.FILTER
                    
                    }
                </i>

                <Menu
                    sx={{ mt: '25px' }}
                    id={'menu-appbar'}
                    className='filter-options'
                    anchorEl={anchorElActions}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                    open={Boolean(anchorElActions)}
                    onClose={handleCloseActionMenu}
                >
                    {filter}
                </Menu>
        </>

    )
};


export default FilterOption;