import axios from 'axios';
import UserService from '../UserService';

// El que corrija el bug de TS tiene un premio (por eso esta como .js)

const addInterceptor = (baseURL) => {
    const client = axios.create({ baseURL });
    client.interceptors.request.use((config) => {
        if (config) {
            config.timeout = 300000;
        }
        if (UserService.isLoggedIn()) {
            const cb = () => {
                config.headers['Content-Type'] = 'application/json';
                config.headers.Authorization = `Bearer ${UserService.getToken()}`;
                return Promise.resolve(config);
            };
            return UserService.updateToken(cb);
        }
    });
    return client;
}

const AxiosInterceptor = {
    addInterceptor,
}

export default AxiosInterceptor;