/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseService from './BaseService';

export default class AvailableStockService extends BaseService {

    private static _instance: AvailableStockService;

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }
    async generateMassiveReport() {
        try {
            const url = 's3/download?key=reporte_disponible.xlsx'
            const response = await this.client({
                url: url,
                method: 'GET',
                responseType: 'arraybuffer',
            });

            return response.data;

        } catch (err: any) {
            this.handleError(err);
            return null;
        }
    }

}