/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useState } from 'react';


interface DownloadContextType {
    pendingDownloads: number;
    addDownload: () => void;
    removeDownload: () => void;
}
const DownloadContext = createContext<DownloadContextType | undefined>(undefined);

export const DownloadProvider = ({children}: any) => {
    const [pendingDownloads, setPendingDownloads] = useState(0);

    const addDownload = () => setPendingDownloads(prev => prev + 1);
    const removeDownload = () => setPendingDownloads(prev => (prev > 0 ? prev - 1 : 0));

    return (
        <DownloadContext.Provider value={{ pendingDownloads, addDownload, removeDownload }}>
            {children}
        </DownloadContext.Provider>
    );
};

export const useDownloadContext = (): DownloadContextType => {
    const context = useContext(DownloadContext);
    if (!context) {
        throw new Error('useDownloadContext debe usarse dentro de un DownloadProvider');
    }
    return context;
};