/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseService from './BaseService';

export default class AuditReportService extends BaseService {

    private static _instance: AuditReportService;

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }

    async generateGridReport(params: Map<string, string>) {
        console.warn(params)
        try {
            let aux = '';
            params.forEach((valueArg) => {
            if(aux) {
                aux += `&${valueArg}`;
            } else {
                aux += `?${valueArg}`;
            }
            });
            const response = await this.client({
                url: `audit-report/xlsx${aux}`,
                method: 'GET',
                responseType: 'arraybuffer',
            });

            return response.data;

        } catch (err: any) {
            this.handleError(err);
            return null;
        }
    }

    async generateHeaderReport(params: Map<string, string>) {
        try {
            let aux = '';
            params.forEach((valueArg) => {
            if(aux) {
                aux += `&${valueArg}`;
            } else {
                aux += `?${valueArg}`;
            }
            });
            const response = await this.client({
                url: `audit-report/xlsx${aux}`,
                method: 'GET',
                responseType: 'arraybuffer',
            });

            return response.data;

        } catch (err: any) {
            this.handleError(err);
            return null;
        }
    }
}