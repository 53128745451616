import React from 'react';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import classes from './PopupSuccessError.module.css';

interface PopupSuccessErrorProps {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  success: boolean;
  successMessage: string | null;
  errorMessage: string[] | null;
}

const PopupSuccessError = ({ open, onClose, loading, success, successMessage, errorMessage }: PopupSuccessErrorProps) => {
  console.warn(errorMessage);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="popup-success-error-title"
      aria-describedby="popup-success-error-description"
    >
      {loading ? (
        <div className="loadingPage">
          <CircularProgress className="circleProgress" />
        </div>
      ) : (
        <div className={classes['helpModal']}>
          {success ? (
            <Typography id="popup-success-error-title" variant="h6" component="h2">
              {successMessage}
            </Typography>
          ) : (
            <>
              <Typography>
                {errorMessage!.length > 1
                  ? 'Los siguientes campos son inválidos:'
                  : 'El siguiente campo es inválido:'}
              </Typography>
              <ul>
                {errorMessage?.map((message, i) => (
                  <li key={i}>
                    <Typography>{message}</Typography>
                  </li>
                ))}
              </ul>
            </>
          )}
          <Button onClick={onClose} className={classes['buttonCancel']}>
            Cerrar
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default PopupSuccessError;
