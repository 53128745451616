/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'
import { ReactElement, ReactNode } from 'react'

class FileErrorDetailMessage {
  type?: string
  code?: string
  message?: string

  constructor({ type = '', code = '', message = '' }: FileErrorDetailMessage) {
    this.type = type
    this.code = code
    this.message = message
  }
}

class FileErrorDetail {
  id?: number
  rawMessage?: string
  row?: string

  public get rawMessageToData(): FileErrorDetailMessage {
    return new FileErrorDetailMessage(JSON.parse(this.rawMessage ?? '{}'))
  }

  constructor({ id, rawMessage = '', row = '' }: FileErrorDetail) {
    this.id = id
    this.rawMessage = rawMessage
    this.row = row
  }
}

class FileResult {
  id?: number
  filename?: string
  created: string
  header?: string
  rowsCount?: number
  errorsCount?: number
  status?: boolean
  errorDetails?: FileErrorDetail[]

  public get statusCode() {
    return this.status ? ((this.errorsCount ?? 0) > 0 ? 'error' : 'success') : 'pending'
  } // 'pending' 'error' 'success'
  public get message() {
    return this.status
      ? this.statusCode === 'success'
        ? 'Subida exitosa'
        : 'Error en la subida'
      : 'Subiendo..'
  }

  constructor({
    id = 0,
    filename = '',
    header = '',
    rowsCount = 0,
    errorsCount = 0,
    status = false,
    created,
    errorDetails,
  }: FileResult) {
    this.id = id
    this.filename = filename
    this.header = header
    this.rowsCount = rowsCount
    this.errorsCount = errorsCount
    this.status = status
    this.errorDetails = errorDetails ?? []
    try {
      const auxDate: Date = new Date(created)
      if (!isNaN(auxDate.getTime())) {
        this.created = moment(created).format('DD-MM-YYYY HH:mm')
      } else {
        this.created = created
      }
    } catch (e) {
      console.error(e)
      this.created = created
    }
  }
}

class CodeDescription {
  id!: number
  code?: string
  description?: string

  constructor(id?: number, code?: string, description?: string) {
    this.id = id ?? this.id
    this.code = code ?? this.code
    this.description = description ?? this.description
  }
}

class ReservedType extends CodeDescription {
  warehouseCode?: string
  reservedTypeCode?: string
  type?: string
  status?: boolean
  reserveTypesDistribution?: WarehouseReservesTypeDistribution[]
  warehouses?: Warehouse[]
}

class TableAction {
  icon?: ReactElement
  label: string
  cssClass?: string
  enable?: boolean = true
  action: (row: any, index: number) => void

  constructor({ label, action, icon, cssClass, enable = true }: TableAction) {
    this.label = label
    this.action = action
    this.icon = icon
    this.cssClass = cssClass
    this.enable = enable
  }
}

type VerticalAlignCell = 'top' | 'middle' | 'bottom'

class TableHeaderCell {
  label: string // Nombre a mostrar
  key: string
  sorteable?: boolean
  sort?: string
  align?: string
  minWidth?: number
  filter?: FilterTable
  onSort?: () => void
  onSelect?: (row: any) => void
  headerStyle?: any
  colStyle?: any
  verticalAlign?: VerticalAlignCell

  constructor({
    label,
    key,
    sorteable,
    sort,
    onSort,
    onSelect,
    align = 'left',
    headerStyle,
    colStyle,
    filter,
  }: TableHeaderCell) {
    this.label = label
    this.key = key
    this.sorteable = sorteable
    this.sort = sort
    this.onSort = onSort
    this.onSelect = onSelect
    this.align = align
    this.headerStyle = headerStyle ?? {}
    this.colStyle = colStyle ?? {}
    this.filter = filter
  }
}

class TableDetailCell {
  data: any
  customDefinitions?: Map<string, ReactNode>
  actions?: TableAction[]
  cssClass: any
  rowClickAction: any

  constructor(
    data: any,
    actions?: TableAction[],
    customDefinitions?: Map<string, ReactNode>,
    cssClass: any = '',
    rowClickAction?: any,
  ) {
    this.data = data
    this.actions = actions
    this.customDefinitions = customDefinitions
    this.cssClass = cssClass
    this.rowClickAction = rowClickAction
  }
}
enum FilterTableType {
  TEXT,
  COMBO,
  MULTIPLE,
  DATE
}

class FilterTable {
  value?: any
  type?: FilterTableType
  placeholder?: string
  data?: CodeDescription[]
  useCodeField?: boolean
  returnEntity?: boolean
  onChange: (newVal: any) => void

  constructor({
    value = '',
    type = FilterTableType.TEXT,
    placeholder = '',
    data,
    onChange,
    useCodeField = false,
    returnEntity = false,
  }: FilterTable) {
    this.value = value
    this.type = type
    this.placeholder = placeholder
    this.data = data
    this.useCodeField = useCodeField
    this.returnEntity = returnEntity
    this.onChange = onChange
  }
}

class Stock {
  id?: number
  distributionCentreCode?: string
  distributionCentreDescription?: string
  productCode?: string
  productDescription?: string
  reserveTypeCode?: string
  reserveTypeDescription?: string
  stockReserved?: number
  newStockReserve?: number
  warehouseCode?: string
  message?: string
  pendingDate?: Date
  pendingQty?: number
  pendingMoveType?: string
  pendingTransactionId?: string

  constructor({
    id,
    distributionCentreCode = '',
    distributionCentreDescription = '',
    productCode = '',
    productDescription = '',
    reserveTypeCode = '',
    reserveTypeDescription = '',
    stockReserved,
    warehouseCode,
    pendingDate,
    pendingQty,
    pendingMoveType,
    pendingTransactionId,
  }: Stock = {}) {
    this.id = id
    this.distributionCentreCode = distributionCentreCode
    this.distributionCentreDescription = distributionCentreDescription
    this.productCode = productCode
    this.productDescription = productDescription
    this.reserveTypeCode = reserveTypeCode
    this.reserveTypeDescription = reserveTypeDescription
    this.stockReserved = stockReserved
    this.warehouseCode = warehouseCode
    this.pendingDate = pendingDate
    this.pendingQty = pendingQty
    this.pendingMoveType = pendingMoveType
    this.pendingTransactionId = pendingTransactionId
  }
}

class Store {
  id!: number
  description: string
  code: string
  status: boolean
  storeType: StoreType
  warehouses: Warehouse[]
  relatedStoreCode: string
  notificationRole: string
  constructor(
    id: number,
    description: string,
    code: string,
    status: boolean,
    storeType: StoreType,
    warehouses: Warehouse[],
    relatedStoreCode: string,
    notificationRole: string,
  ) {
    this.id = id ?? this.id
    this.description = description
    this.code = code
    this.status = status
    this.storeType = storeType
    this.warehouses = warehouses
    this.relatedStoreCode = relatedStoreCode
    this.notificationRole = notificationRole
  }
}

class StoreType {
  id!: number
  description: string
  code: string
  status: boolean
  constructor(id: number, description: string, code: string, status: boolean) {
    this.id = id ?? this.id
    this.description = description
    this.code = code
    this.status = status
  }
}

class Warehouse {
  id!: number
  description: string
  code: string
  status: boolean
  saleable: boolean
  platform: string
  platformReserveType: string
  transferProduct: boolean
  requestProduct: boolean
  reserveType: ReservesType
  warehouseType: WarehouseType
  constructor(
    id: number,
    description: string,
    status: boolean,
    code: string,
    saleable: boolean,
    platform: string,
    platformReserveType: string,
    transferProduct: boolean,
    requestProduct: boolean,
    reserveType: ReservesType,
    warehouseType: WarehouseType,
  ) {
    this.id = id ?? this.id
    this.description = description
    this.code = code
    this.status = status
    this.saleable = saleable
    this.platform = platform
    this.platformReserveType = platformReserveType
    this.transferProduct = transferProduct
    this.requestProduct = requestProduct
    this.reserveType = reserveType
    this.warehouseType = warehouseType
  }
}

class WarehouseType {
  id!: number
  description: string
  code: string
  status: boolean
  constructor(id: number, description: string, code: string, status: boolean) {
    this.id = id ?? this.id
    this.description = description
    this.code = code
    this.status = status
  }
}

class ReservesType {
  id?: number
  description?: string
  code?: string
  type?: string
  constructor({ id, description, code, type }: ReservesType = {}) {
    this.id = id ?? this.id
    this.description = description
    this.code = code
    this.type = type
  }
}

class ErrorTransaction {
  created: string
  transactionType: string
  transactionData: string
  transactionId: string
  error: string
  usuario: string

  constructor({
    created,
    transactionType,
    transactionData,
    transactionId,
    error,
    usuario,
  }: ErrorTransaction) {
    this.created = created
    this.transactionType = transactionType
    this.transactionData = transactionData
    this.transactionId = transactionId
    this.error = error
    this.usuario = usuario
  }
}

class MovementReport {
  date: string
  distributionCentre: string
  id: number
  movementType: string
  quantity: number
  reserveType: string
  sku: string
  status: string
  warehouse: string
  createdBy: string
  changedBy: string
  deletedBy: string
  transactionId: string

  constructor({
    date,
    distributionCentre,
    id,
    movementType,
    quantity,
    reserveType,
    sku,
    status,
    warehouse,
    createdBy,
    changedBy,
    deletedBy,
    transactionId,
  }: MovementReport) {
    this.date = date
    this.distributionCentre = distributionCentre
    this.id = id
    this.movementType = movementType
    this.quantity = quantity
    this.reserveType = reserveType
    this.sku = sku
    this.status = status
    this.warehouse = warehouse
    this.createdBy = createdBy
    this.changedBy = changedBy
    this.deletedBy = deletedBy
    this.transactionId = transactionId
  }
}

class WarehouseReservesTypeDistribution extends CodeDescription {
  reservesType?: ReservesType
  warehouse?: Warehouse
  distribution?: number
  constructor({ id, reservesType, warehouse, distribution }: WarehouseReservesTypeDistribution) {
    super()
    this.id = id ?? this.id
    this.reservesType = reservesType ?? this.reservesType
    this.warehouse = warehouse ?? this.warehouse
    this.distribution = distribution ?? this.distribution
  }
}

class MovementType extends CodeDescription {}

class DistributionCentre extends CodeDescription {
  externalCode?: number
  status?: boolean

  constructor({ id, code, description, externalCode, status }: Partial<DistributionCentre> = {}) {
    super()
    this.id = id ?? this.id
    this.code = code ?? this.code
    this.description = description ?? this.description
    this.externalCode = externalCode ?? this.externalCode
    this.status = status ?? this.status
  }
}

class WarehouseStock {
  id?: number
  sku?: string
  productDescription?: string
  stockMinimum?: number
  stockMaximun?: number
  stockAlert?: number
  warehouse?: Warehouse
  distributionCentre?: DistributionCentre
  stockMinimumTransfer?: number

  constructor({
    sku,
    productDescription,
    stockMinimum,
    stockMaximun,
    stockAlert,
    warehouse,
    distributionCentre,
    stockMinimumTransfer,
  }: WarehouseStock = {}) {
    this.sku = sku ?? this.sku
    this.productDescription = productDescription ?? this.productDescription
    this.stockMinimum = stockMinimum ?? this.stockMinimum
    this.stockMaximun = stockMaximun ?? this.stockMaximun
    this.stockAlert = stockAlert ?? this.stockAlert
    this.warehouse = warehouse ?? this.warehouse
    this.distributionCentre = distributionCentre ?? this.distributionCentre
    this.stockMinimumTransfer = stockMinimumTransfer ?? this.stockMinimumTransfer
  }
}

class WarehouseStockMovement {
  id?: number
  created?: Date
  sku?: string
  stockMove?: number
  status?: string
  transactionId?: string
  warehouse?: Warehouse
  warehouseStock?: WarehouseStock
  movementType?: MovementType

  constructor({
    id,
    created,
    sku,
    stockMove,
    status,
    transactionId,
    warehouse,
    warehouseStock,
    movementType,
  }: WarehouseStockMovement = {}) {
    this.id = id ?? this.id
    this.created = created ?? this.created
    this.sku = sku ?? this.sku
    this.stockMove = stockMove ?? this.stockMove
    this.status = status ?? this.status
    this.transactionId = transactionId ?? this.transactionId
    this.warehouse = warehouse ?? this.warehouse
    this.warehouseStock = warehouseStock ?? this.warehouseStock
    this.movementType = movementType ?? this.movementType
  }
}

class Notification {
  id!: number
  created?: string
  title?: string
  description?: string
  status?: string
  user?: string
  type?: string
  criticality?: string
  system?: string

  constructor({
    id,
    created,
    title,
    description,
    status,
    user,
    type,
    criticality,
    system,
  }: Notification) {
    this.id = id ?? this.id
    this.created = created ?? this.created
    this.title = title ?? this.title
    this.description = description ?? this.description
    this.status = status ?? this.status
    this.user = user ?? this.user
    this.type = type ?? this.type
    this.criticality = criticality ?? this.criticality
    this.system = system ?? this.system
  }
}

class NotificationResponse {
  count: number
  results: Notification[]

  constructor({ count, results }: NotificationResponse) {
    this.count = count
    this.results = results
  }
}

export {
  FileResult,
  TableAction,
  // VerticalAlignCell,
  TableHeaderCell,
  TableDetailCell,
  Stock,
  CodeDescription,
  DistributionCentre,
  FilterTableType,
  FilterTable,
  FileErrorDetail,
  FileErrorDetailMessage,
  Store,
  StoreType,
  ReservedType,
  Warehouse,
  WarehouseStock,
  WarehouseType,
  ReservesType,
  ErrorTransaction,
  MovementReport,
  WarehouseReservesTypeDistribution,
  WarehouseStockMovement,
  Notification,
  NotificationResponse,
  //   type DistributionCentre,
}
