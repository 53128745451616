import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import MainLayout from './components/layout/MainLayout'
import { routes } from './routes'
import RenderOnAuthenticated from './components/auth/RenderOnAuthenticated'
import { ToastContainer } from 'react-toastify'
import { DownloadProvider } from './providers/DownloadContext'

function App() {
  return (
    <div className='zoom-container'>
    <ToastContainer />
    <DownloadProvider>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <RenderOnAuthenticated>
          <Routes>
              <Route path='/' element={<MainLayout />}>
                <Route path='/' element={<Navigate to='/stock' replace />} />
                {routes}
              </Route>
              
              <Route path='/*' element={<Navigate to='/stock' replace />} />
          </Routes>
        </RenderOnAuthenticated>
      </BrowserRouter>
    </DownloadProvider>
    </div>
  )
}

export default App
