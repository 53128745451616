import { useEffect, useState } from 'react';
import InputFilter from '../input-filter/InputFilter';
import './InputDateRangeFilter.css'
import moment from 'moment';


type InputRangeVal = {
    from?: Date;
    to?:Date;
}

type InputRangeProps = {
    valueFrom?: Date;
    valueTo?: Date;
    onChange(newVal:InputRangeVal): void;
}


const InputDateRangeFilter = ({ valueTo, valueFrom, onChange }:InputRangeProps)=> {
    const [auxValueFrom, setAuxValueFrom] = useState<Date|undefined>(valueFrom);
    const [auxValueTo, setAuxValueTo] = useState<Date|undefined>(valueTo);
    const [error, setError] = useState<string | null>(null);

    const formatDateToInput = (date?: Date) => {
        return date ? moment(date).format('YYYY-MM-DD') : '';
    };

    const handleDateChangeFrom = (newVal?: string) => {
        const parsedDate = newVal ? moment(newVal, 'YYYY-MM-DD').toDate() : undefined;
        if (parsedDate && auxValueTo && parsedDate > auxValueTo) {
            setError('La fecha "Desde" no puede ser mayor que la fecha "Hasta" o la fecha de hoy.');
        } else {
            setAuxValueFrom(parsedDate);
            setError(null);
        }
    };

    const handleDateChangeTo = (newVal?: string) => {
        const parsedDate = newVal ? moment(newVal, 'YYYY-MM-DD').toDate() : undefined;
        if (auxValueFrom && parsedDate && parsedDate < auxValueFrom) {
            setError('La fecha "Hasta" no puede ser menor que la fecha "Desde".');
        } else {
            setAuxValueTo(parsedDate);
            setError(null);
        }
    };

    useEffect(()=> {
        setAuxValueFrom(valueFrom)
        setAuxValueTo(valueTo)
    },[valueFrom, valueTo])

    useEffect(() => {
        onChange({from: auxValueFrom, to: auxValueTo});
    }, [auxValueFrom, auxValueTo])
    

    return(   
        <div>
            <div className='input-date-container'>
                <span className='title-input-range'>Desde</span>
                <InputFilter 
                    placeholder='' 
                    type='date' 
                    value={formatDateToInput(auxValueFrom)} 
                    onChange={handleDateChangeFrom}
                    debounceTime={500}
                />
            </div>
            <div style={{display: 'inline-block', width: '10%', position: 'relative', left: '15px'}}>
                -
            </div>
            <div className='input-date-container'>
                <span className='title-input-range'>Hasta</span>
                <InputFilter 
                    placeholder='' 
                    type='date' 
                    value={formatDateToInput(auxValueTo)} 
                    onChange={handleDateChangeTo}
                    debounceTime={500}
                />
            </div>
            {error && <div style={{color: 'red'}}>{error}</div>}
        </div>
    )
}


export default InputDateRangeFilter;