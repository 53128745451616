/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, CircularProgress, FormControl, MenuItem, Select } from '@mui/material'
import Topbar from '../../components/common/topbar/Topbar'
import DataTable from '../../components/common/datatable/Datatable'
import { useEffect, useMemo, useState } from 'react'
import NewsanIcons from '../../components/common/Icons'
import classes from './Stores.module.css'
import { FilterTable, FilterTableType, Store, StoreType, TableAction, TableDetailCell, TableHeaderCell, Warehouse} from '../../model/models-module'
import CloseIcon from '@mui/icons-material/Close'
import {useData} from '../../components/hooks/useData'
import axios from 'axios';
import AxiosInterceptor from '../../services/http/AxiosInterceptor';
import PopupSuccessError from '../../components/common/popup-success-error/PopupSuccessError'
import EditIcon from '@mui/icons-material/Edit';
import RoleService from '../../services/RoleService'
import { GridDTO, Role } from '../../model/dto-module'
import { addParam } from '../../utils/CommonUtils'
import WarehouseService, { COMBO, NORMAL } from '../../services/WarehouseService'

type typeBody = {
  description?: string,
  code?: string,
  status?: boolean,
  storeTypeCode?: string,
  warehouseId?: number,
  warehouseComboId?: number,
  relatedStoreCode: string
  notificationRole: string
}

const client = AxiosInterceptor.addInterceptor(process.env.REACT_APP_API_BASE_URL)

const Stores = () => {
  const [dataShow, setDataShow] = useState<any>([])
  const [dataTo, setDataTo] = useState<number>(20)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [showForm, setShowForm] = useState<boolean>(false)
  const [newEditItem, setNewEditItem] = useState<Store | undefined>()
  const [countItems, setCountItems] = useState<number>(0);
  const [dataOffset, setDataOffset] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [baseEndpoint, setBaseEndpoint] = useState<string>('/stores'); 
  const [endpoint, setEndpoint] = useState<string>('');
  const [roleServ] = useState<RoleService>(RoleService.Instance);
  const [loadingStore, errorStore, dataStore]= useData<GridDTO<Store>>(endpoint, 'GET')
  const [loadingStoresTypes, errorStoreTypes, dataStoresTypes] = useData<GridDTO<StoreType>>('/stores-types?status=true', 'GET')
  const [warehouseService] = useState<WarehouseService>(WarehouseService.Instance);

  const [selectedDescription, setSelectedDescription] = useState<string>('');
  const [selectedCode, setSelectedCode] = useState<string>('');
  const [selectedRelatedStoreCode, setSelectedRelatedStoreCode] = useState<string>('');
  const [selectedEstado, setSelectedEstado] = useState<boolean>();
  const [selectedStoreType, setSelectedStoreType] = useState<StoreType>();
  const [selectedWarehouses, setSelectedWarehouses] = useState<Warehouse>();
  const [selectedWarehousesCombo, setSelectedWarehousesCombo] = useState<Warehouse>();
  const [selectedRole, setSelectedRole] = useState<string>('');
  
  const [sortArg, setSortArg] = useState<string>('');
  const [newSort, setNewSort] = useState<number>(0);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [sort] = useState<Map<string, string>>(new Map());
  const [params] = useState<Map<string,string>>(new Map());  
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [errorResponse, setErrorResponse] = useState<string[] | null>(null);
  const [dataResponse, setDataResponse] = useState<string | null>(null);
  const [roles, setRoles] = useState<Role[]>([]);
  const [descriptionFilter, setDescriptionFilter] = useState<string>();
  const [statusFilter, setStatusFilter] = useState<string>();

  const [loadingWarehouses, setLoadingWarehouses] = useState<boolean>(false)
  const [dataWarehouses, setDataWarehouses] = useState<Warehouse[]>([])
  const [dataWarehousesCombo, setDataWarehousesCombo] = useState<Warehouse[]>([])

  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [dataHeader, setDataHeader] = useState<TableHeaderCell[]>([]);

  useEffect(()=> {
    if(!initialized) {
      const isNotLoading = !loadingStore && !loadingWarehouses && !loadingStoresTypes;
      const hasData = dataStore != null && dataStoresTypes != null;
      setInitialized(isNotLoading && hasData);
      setDataTo(20);
    }
  }, [loadingStore, loadingWarehouses, loadingStoresTypes]);

  useMemo(()=>{
    
    setLoadingWarehouses(true);
    warehouseService.findAll({dataTo: 0, typeReserveType:COMBO})
    .then((data)=>{
      setDataWarehousesCombo(data.results)
      warehouseService.findAll({dataTo: 0, typeReserveType:NORMAL})
      .then((data)=>{
        setDataWarehouses(data.results);
      }).finally(()=>setLoadingWarehouses(false))
    });

    roleServ.findAll().then((data)=>{
      setRoles(data);
    });
  }, [setRoles]);

  useEffect(()=> {
      addParam(dataTo, 'limit', params, baseEndpoint, setEndpoint);
  }, [dataTo, baseEndpoint])

  useEffect(()=> {
    if(initialized){
      addParam(dataOffset, 'offset', params, baseEndpoint, setEndpoint);
    }
  }, [dataOffset, baseEndpoint])

  useEffect(()=> {
    if(initialized){
      setDataOffset(0)
      setDataShow([]);
      addParam(sortArg, 'sort', params, baseEndpoint, setEndpoint);
    }
  }, [sortArg, baseEndpoint])

  useEffect(() => {
    if(initialized){
      addParam(descriptionFilter, 'description', params, baseEndpoint, setEndpoint)
    }
  }, [descriptionFilter, baseEndpoint])

  useEffect(() => {
    if(initialized){
      addParam(statusFilter, 'status', params, baseEndpoint, setEndpoint)
    }
  }, [statusFilter, baseEndpoint]) 

  const fetchData = async ( body?: typeBody) => {
    try{
      setLoadingResponse(true)
      if (isEdit){
        await client.patch(`/stores/${newEditItem?.id}`, body)
        setDataResponse('Modificado con éxito!')
        setErrorResponse(null)
        setIsEdit(false)
        setNewEditItem(undefined)
      }else{
        await client.post('/stores', body)
        setDataResponse('Creado con éxito')
        setErrorResponse(null)
      }
      formatForm()
      setShowForm(false)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setErrorResponse(error.response?.data.message);
      } else if (error instanceof Error) {
        setErrorResponse([error.message]);
      } else {
        setErrorResponse(['Hubo un error en la solicitud']);
      }
    } finally {
      setLoadingResponse(false);
    } 
  }

  const formatForm = () => {
    setSelectedCode('')
    setSelectedDescription('')
    setSelectedEstado(undefined)
    setSelectedStoreType(undefined)
    setSelectedWarehouses(undefined)
    setSelectedWarehousesCombo(undefined)
    setSelectedRelatedStoreCode('')
    setSelectedRole('')
  }

  const saveFormInfo = () => {
    // Validar campos del formulario
    if (!selectedCode || !selectedDescription 
      || (selectedEstado == null || undefined) 
      || selectedStoreType == undefined || !selectedRelatedStoreCode|| selectedRole == undefined || !selectedRole){
      alert('Se deben completar todos los campos')
    } else {
      const temporalObject: typeBody = {
        description: selectedDescription,
        code: selectedCode,
        status: selectedEstado,
        storeTypeCode: selectedStoreType?.code,
        warehouseId: selectedWarehouses?.id,
        warehouseComboId: selectedWarehousesCombo?.id,
        relatedStoreCode : selectedRelatedStoreCode,
        notificationRole: selectedRole
      }
      setShowPopup(true);
      fetchData(temporalObject)
    }
  }

  const deleteTemplateConfig = async (id: number) => {
    try{
      setLoadingResponse(true)
        await client.delete(`/stores/${id}`)
        setDataResponse('Eliminado con éxito!')
        setErrorResponse(null)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setErrorResponse(error.response?.data.message);
      } else if (error instanceof Error) {
        setErrorResponse([error.message]);
      } else {
        setErrorResponse(['Hubo un error en la solicitud']);
      }
    } finally {
      setLoadingResponse(false);
    } 
  }

  useEffect(() => {
    if (baseEndpoint == '') setBaseEndpoint('/stores');

    if (!errorStoreTypes && dataStoresTypes){
      dataStoresTypes?.results.sort((a, b) => a.id - b.id);
    }

    if (!errorStore && dataStore) {
      setCountItems(dataStore.count);
      setDataShow(dataStore.results.map((r: Store) => {
          return new TableDetailCell({description: r.description, status: r.status ? 'Activa' : 'Inactiva'}, [
            new TableAction({label: 'Editar', action: () => {
              formatForm()
              setNewEditItem(r)
              setIsEdit(true)
              setShowForm(true)
            },
            icon: <span className={classes.editIcon}><EditIcon></EditIcon></span>
            }),
            new TableAction({label: 'Eliminar', action: async () => {
              setShowPopup(true);
              await deleteTemplateConfig(r.id);
            },
            icon: NewsanIcons.DELETE
            }),
          ],
          undefined, classes['storesDataTable'])
      })
      )
    }
  }, [setDataShow, baseEndpoint, dataStore])

  useEffect(() => {
    let filterLoadeed = !loadingStore && dataStore != null &&  dataHeader.length === 0;
    filterLoadeed = filterLoadeed && !loadingStoresTypes && !loadingWarehouses && dataStoresTypes?.results != null;

    if(filterLoadeed) {
      setDataHeader([
        new TableHeaderCell({ label: 'Descripción', key: 'description', sorteable: true, filter: new FilterTable({onChange: (newVal)=>{setDescriptionFilter(newVal)}, placeholder: 'Busca aqui...' }) }),
        new TableHeaderCell({ label: 'Estado', key: 'status', sorteable: true, filter: new FilterTable({onChange: (newVal)=>{setStatusFilter(newVal)}, type: FilterTableType.COMBO, data: [{id: 0, code: 'false', description: 'Inactiva'}, {id: 1, code: 'true', description: 'Activa'}], useCodeField: true })}),
      ])
    }
  })
  
  useEffect(()=>{

    if(newEditItem && isEdit){
      const dataWh = dataWarehouses.find((dw)=>newEditItem.warehouses.find(w=>w.id === dw.id) != undefined)
      const dataWhCombo = dataWarehousesCombo.find((dw)=>newEditItem.warehouses.find(w=>w.id === dw.id) != undefined)
      // Setear campos del formulario con los valores del item a editar
      setSelectedDescription(newEditItem.description);
      setSelectedCode(newEditItem.code);
      setSelectedEstado(newEditItem.status);
      setSelectedStoreType(newEditItem?.storeType)
      setSelectedWarehouses( dataWh)
      setSelectedWarehousesCombo(dataWhCombo)
      setSelectedRelatedStoreCode(newEditItem.relatedStoreCode);
      setSelectedRole(newEditItem.notificationRole);
    }
  }, [loadingStore, newEditItem, isEdit]);

  useEffect(()=>{
    if(newSort > 0) {

      let args = '';
      sort.forEach((value)=>{
        if(args === '') {
          args += value;
        } else {
          args += `,${value}`;
        }
      });
      setSortArg(args);
      setNewSort(0);
    }
  }, [newSort, setSortArg, setNewSort]);

  const onSort = (key: string, order: string)=>{ 
    if(order === ''){ 
      sort.delete(key)
    } else {
      sort.set(key, `${key}:${order}`);
    }
    setNewSort(1);
  };

  function reloadTable(){
    setBaseEndpoint('')
    setEndpoint('')
  }

  return (
    <>
    {
      loadingStore ?
      <div className='loadingPage'>
        <CircularProgress className='circleProgress'/>
      </div>
      : null
    }
    <div className={classes.storesPage}>
      <Topbar title={'Tiendas'} hasReloadIcon reloadPage={reloadTable}/>
      <div className={classes['container-abm-page']}>
      {
          errorStore ?
          <>Ha habido un problema!</>
          :
        <div
        className={
          showForm ? classes['table-templates-container-form-visible'] : classes['table-templates-container']
        }
        >
          <DataTable
            headers={dataHeader}
            rows={dataShow}
            countItems={countItems}
            rowsPerPage={dataTo}
            hasActions={true}
            appendOnScrollToBottom={false}
            onPageSizeChange={(size) => {setDataTo(size); setDataOffset(0); setPage(1);}}
            onPageChange={(page: number)=>{ setPage(page); setDataOffset(page-1) }}
            pageSelected={page}
            pageable={true}
            selectable={false}
            onSortChange={onSort}
            className={classes.storesDataTable}
          ></DataTable>
        </div>
      }
      {initialized && showForm ? (
        <div className={classes['abm-container']}>
          <div className={isEdit ? classes['edit-form-container'] : classes['add-form-container']}>
            <span className={classes['title-form']}>
              {NewsanIcons.ADD_STORE}{' '}
              <span className={classes['title-form-text']}>{isEdit ? 'Editar' : 'Agregar'} </span></span>
            <CloseIcon
              className={classes['form-close-button']}
              onClick={() => {
                formatForm()
                setShowForm(false)
                setIsEdit(false)
                setNewEditItem(undefined)
              }}
            ></CloseIcon>
            <span className={classes['setting-attr-title']}>Nombre de la Tienda*</span>
            <input
              type='text'
              className={classes['setting-attr-input']}
              placeholder='Nombre de la Tienda'
              defaultValue={selectedDescription}
              onChange={(e) => {
                setSelectedDescription(e.target.value);
              }}
              onInput={(e) => {
                const value = (e.target as HTMLInputElement).value;
                if(value == '') setSelectedDescription(value);
              }}
            />

            <span className={classes['setting-attr-title']}>Código de la Tienda*</span>
            <input
              type='text'
              className={classes['setting-attr-input']}
              placeholder='Código de la Tienda'
              defaultValue={selectedCode}
              onChange={(e) => {
                setSelectedCode(e.target.value);
              }}
              onInput={(e) => {
                const value = (e.target as HTMLInputElement).value;
                if(value == '') setSelectedCode(value);
              }}
            />

            <span className={classes['setting-attr-title']}>Estado*</span>
            <FormControl className={classes['select-filter']} size='small'>
              <Select
                value={selectedEstado ? 'Activa' : selectedEstado == false ? 'Inactiva': ''}
                onChange={(e) => {
                  if (e.target.value == 'Activa') {
                    setSelectedEstado(true)
                  } else if (e.target.value == 'Inactiva') {
                    setSelectedEstado(false)
                  }
                }}
              >
                <MenuItem value={''}>
                  <em className={classes['no-selection']}>Seleccionar</em>
                </MenuItem>
                <MenuItem className={classes['select-option']} key={'Activa'} value={'Activa'}>
                  Activa
                </MenuItem>
                <MenuItem className={classes['select-option']} key={'Inactiva'} value={'Inactiva'}>
                  Inactiva
                </MenuItem>
              </Select>
            </FormControl>

            <span className={classes['setting-attr-title']}>Tipo de tienda*</span>
            <FormControl className={classes['select-filter']} size='small'>
              <Select
                value={selectedStoreType ? selectedStoreType?.id.toString() : newEditItem?.storeType.id.toString()}
                onChange={(e) => setSelectedStoreType(dataStoresTypes?.results.find(store => store.id.toString() === e.target.value))}
              >
                <MenuItem value={''}>
                  <em className={classes['no-selection']}>Seleccionar</em>
                </MenuItem>
                {dataStoresTypes?.results.map((e)=>(
                  <MenuItem className={classes['select-option']} key={e.id} value={e.id.toString()}>
                  {e.description}
                </MenuItem>
              ))}
                
                
              </Select>
            </FormControl>

            <span className={classes['setting-attr-title']}>Almacen*</span>
            <FormControl className={classes['select-filter']} size='small'>
              <Select
                value={selectedWarehouses ? selectedWarehouses.id.toString() : ''}
                onChange={(e) => setSelectedWarehouses(dataWarehouses?.find(warehouse => warehouse.id.toString() === e.target.value))}
              >
                <MenuItem value={''}>
                  <em className={classes['no-selection']}>Seleccionar</em>
                </MenuItem>
                {dataWarehouses?.map((e)=>(
                  <MenuItem className={classes['select-option']} key={e.id} value={e.id.toString()}>
                  {e.description}
                </MenuItem>
              ))}
                
                
              </Select>
            </FormControl>

            <span className={classes['setting-attr-title']}>Almacen Combo*</span>
            <FormControl className={classes['select-filter']} size='small'>
              <Select
                value={selectedWarehousesCombo ? selectedWarehousesCombo.id.toString() : ''}
                // value={selectedWarehousesCombo ? selectedWarehousesCombo?.id.toString() : (newEditItem?.warehouses.filter(w=>w.reserveType.type === COMBO).length != 0 ? newEditItem?.warehouses.filter(w=>w.reserveType.type === COMBO)[0].id.toString() : '')}
                onChange={(e) => setSelectedWarehousesCombo(dataWarehousesCombo?.find(warehouse => warehouse.id.toString() === e.target.value))}
              >
                <MenuItem value={''}>
                  <em className={classes['no-selection']}>Seleccionar</em>
                </MenuItem>
                {dataWarehousesCombo?.map((e)=>(
                  <MenuItem className={classes['select-option']} key={e.id} value={e.id.toString()}>
                  {e.description}
                </MenuItem>
              ))}
                
                
              </Select>
            </FormControl>

            <span className={classes['setting-attr-title']}>Código de tienda relacionada*</span>
            <input
              type='text'
              className={classes['setting-attr-input']}
              placeholder='Código de tienda relacionada'
              defaultValue={selectedRelatedStoreCode}
              onChange={(e) => {
                setSelectedRelatedStoreCode(e.target.value);
              }}
              onInput={(e) => {
                const value = (e.target as HTMLInputElement).value;
                if(value == '') setSelectedRelatedStoreCode(value);
              }}
            />
            <span className={classes['setting-attr-title']}>Rol de Notificación*</span>
            <FormControl className={classes['select-filter']} size='small'>
              <Select
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
              >
                <MenuItem value={''}>
                  <em className={classes['no-selection']}>Seleccionar</em>
                </MenuItem>
                {roles.map((e, idx)=>(
                  <MenuItem className={classes['select-option']} key={idx} value={e.name}>
                  {e.name}
                </MenuItem>
              ))}
              </Select>
            </FormControl>
          
            <div className={classes['buttons-container']}>
              <Button
                variant='contained'
                className={classes['btn-newsan-cancel']}
                onClick={() => {
                  setShowForm(false)
                  setIsEdit(false)
                  setNewEditItem(undefined)
                  setDataResponse(null)
                  setErrorResponse(null)
                  formatForm()
                }}
              >
                Cancelar
              </Button>
              <Button
                variant='contained'
                onClick={async() => {await saveFormInfo();}}
                className={
                  isEdit ? classes['button-newsan-red'] : classes['button-newsan-grey']
                }
              >
                {isEdit ? 'Guardar' : 'Cargar'}
              </Button>
            </div>
          </div>
        </div>
      ) : (
          <span className={classes['btn-show-form']} onClick={() => setShowForm(!showForm)}>
            {NewsanIcons.ADD_STORE}
          </span>
      )}
      </div>
      {
        showPopup ? (<PopupSuccessError
          open={showPopup}
          onClose={() =>{ 
            setShowPopup(false);
            setDataResponse(null)
            setBaseEndpoint(errorResponse == null ? '' : baseEndpoint)
            setErrorResponse(null)
          }}
          loading={loadingResponse}
          success={dataResponse !== null}
          successMessage={dataResponse}
          errorMessage={errorResponse}
        />)
      :
      <></>
      }
    </div>
    </>
  )
}

export default Stores
