/* eslint-disable */
import { DropdownOptionsType } from '../components/common/autocomplete-input/AutocompleteInput';
import BaseService from './BaseService';


export default class UserWarehousesService extends BaseService {
  private static _instance: UserWarehousesService

  public static get Instance() {
    if (!this._instance) {
      this._instance = new this()
    }
    return this._instance
  }

  async getWarehousesByUser(user?: string) {
    try {
      const response = await this.client<{ data: DropdownOptionsType[] }>({
        url: `/user-warehouses/byAssigned?user=${user}`,
        method: 'GET',
      })
      return response.data
    } catch (err: any) {
      // this.handleError(err)
      console.log('err', err)
      throw err
    }
  }

  async getAllAsDropdownAutocomplete(searchCriteria?: string) {
    try {
      const response = await this.client<{ data: DropdownOptionsType[] }>({
        url: '/user-warehouses',
        method: 'GET',
        params: { searchCriteria },
      })
      return response.data
    } catch (err: any) {
      // this.handleError(err)
      console.log('err', err)
      throw err
    }
  }

  async assignUserWarehouse(username: string, idWarehouse: number) {
    try {
      const response = await this.client<{ data: DropdownOptionsType[] }>({
        url: '/user-warehouses/assign',
        method: 'POST',
        data: {
          user: username,
          idWarehouse: idWarehouse,
        },
      })
      return response.data
    } catch (err: any) {
      // this.handleError(err)
      console.log('err', err)
      throw err
    }
  }

  async unassignUserWarehouse(username: string, idWarehouse: number) {
    try {
      const response = await this.client<{ data: DropdownOptionsType[] }>({
        url: '/user-warehouses/unassign',
        method: 'DELETE',
        data: {
          user: username,
          idWarehouse: idWarehouse,
        },
      })
      return response.data
    } catch (err: any) {
      // this.handleError(err)
      console.log('err', err)
      throw err
    }
  }

  async massiveAssignUserWarehouse(username: string, warehouses: number[]) {
    try {
      const response = await this.client<{ data: DropdownOptionsType[] }>({
        url: '/user-warehouses/massive-assign',
        method: 'POST',
        data: {
          user: username,
          warehouses: warehouses,
        },
      })
      return response.data
    } catch (err: any) {
      // this.handleError(err)
      console.log('err', err)
      throw err
    }
  }

  async massiveUnassignUserWarehouse(username: string, warehouses: number[]) {
    try {
      const response = await this.client<{ data: DropdownOptionsType[] }>({
        url: '/user-warehouses/massive-unassign',
        method: 'DELETE',
        data: {
          user: username,
          warehouses: warehouses,
        },
      })
      return response.data
    } catch (err: any) {
      // this.handleError(err)
      console.log('err', err)
      throw err
    }
  }
}