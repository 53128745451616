import { Button, Menu } from '@mui/material';
import { useEffect, useState } from 'react';
import InputFilter from '../input-filter/InputFilter';
import './InputDateRange.css'
import moment from 'moment';
import { formatDate } from '../../../utils/CommonUtils';


type InputRangeVal = {
    from?: Date;
    to?:Date;
}

type InputRangeProps = {
    valueFrom?: Date;
    valueTo?: Date;
    onChange(newVal:InputRangeVal): void;
    bothRequired?: boolean
}


const InputDateRange = ({ valueTo, valueFrom, onChange, bothRequired = false }:InputRangeProps)=> {
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [auxValueFrom, setAuxValueFrom] = useState<Date|undefined>(valueFrom);
    const [auxValueTo, setAuxValueTo] = useState<Date|undefined>(valueTo);
    const [value, setValue] = useState<string>('');
    const [updateView, setUpdateView] = useState<number>(1);
    const [error, setError] = useState<string | null>(null);

    const formatDateToInput = (date?: Date) => {
        return date ? moment(date).format('YYYY-MM-DD') : '';
    };

    const handleDateChangeFrom = (newVal?: string) => {
        const parsedDate = newVal ? moment(newVal, 'YYYY-MM-DD').toDate() : undefined;
        setAuxValueFrom(parsedDate);
    };

    const handleDateChangeTo = (newVal?: string) => {
        const parsedDate = newVal ? moment(newVal, 'YYYY-MM-DD').toDate() : undefined;
        setAuxValueTo(parsedDate);
    };


    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        if(bothRequired && auxValueFrom == undefined && auxValueTo != undefined){
            setError('La fecha "Desde" es requerida.')
        } else if (bothRequired && auxValueTo == undefined && auxValueFrom != undefined){
            setError('La fecha "Hasta" es requerida.')
        } else if (auxValueFrom && auxValueTo && auxValueTo < auxValueFrom){
            setError('La fecha "Hasta" no puede ser menor que la fecha "Desde".');
        } else if (auxValueFrom && auxValueTo && auxValueFrom > auxValueTo){
            setError('La fecha "Desde" no puede ser mayor que la fecha "Hasta" o la fecha de hoy.');
        } else {
            setError(null)
            onChange({from: auxValueFrom, to: auxValueTo});
            setValue(`${formatDate(auxValueFrom) ?? ''} - ${formatDate(auxValueTo) ?? ''}`);
            setUpdateView(updateView+1)
            setAnchorElUser(null)
        }
    }

    useEffect(() => {
        if (!error) onChange({from: auxValueFrom, to: auxValueTo});
    }, [auxValueFrom, auxValueTo])
    

    return   <>
            <div className='input-range-container' onClick={handleOpenUserMenu}>
            {auxValueFrom || auxValueTo ? value : 'Seleccionar'}
            </div>

                <Menu
                    id='menu-appbar'
                    className='menu-range-date'
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                <div>
                    <div className='input-date-container'>
                        <span className='title-input-range'>Desde</span>
                        <InputFilter 
                            placeholder='' 
                            type='date' 
                            value={formatDateToInput(auxValueFrom)} 
                            onChange={handleDateChangeFrom}
                            debounceTime={500}
                        />
                    </div>
                    <div style={{display: 'inline-block', width: '15%', textAlign: 'center'}}>
                        -
                    </div>
                    <div className='input-date-container'>
                        <span className='title-input-range'>Hasta</span>
                        <InputFilter 
                            placeholder='' 
                            type='date' 
                            value={formatDateToInput(auxValueTo)} 
                            onChange={handleDateChangeTo}
                            debounceTime={500}
                        />
                    </div>
                    {error && <div style={{color: 'red', fontSize: 13, marginTop: 15}}>{error}</div>}
                </div>
                <Button className='button-calendar' onClick={()=>{
                    const to = new Date();
                    const from = moment(to).add(-6, 'day').toDate();
                    setAuxValueFrom(from);
                    setAuxValueTo(to);
                    setUpdateView(updateView+1)
                }}>última semana</Button>
                <Button className='button-calendar' onClick={()=>{
                    const to = moment().toDate();
                    const from = moment().add(-1, 'month').toDate();
                    console.warn(from)
                    setAuxValueFrom(from);
                    setAuxValueTo(to);
                    setUpdateView(updateView+1)

                }}>último mes</Button>
                <Button className='button-calendar' onClick={()=>{
                    const to = new Date();
                    const from = moment(to).add(-1, 'year').toDate();
                    setAuxValueFrom(from);
                    setAuxValueTo(to);
                    setUpdateView(updateView+1)

                }}>último año</Button>
                {auxValueFrom || auxValueTo ? <Button className='button-clear' onClick={()=> {setAuxValueFrom(undefined); setAuxValueTo(undefined); setError(null)}}>Borrar Filtro</Button>: null}
              </Menu>
    </>;
}


export default InputDateRange;