import { Box, Chip, IconButton, Popover, TextField} from '@mui/material';
import { useEffect, useState } from 'react';
import classes from './MultipleTextInput.module.css'
import NewsanIcons from '../Icons';

type InputRangeProps = {
    label?: string
    selectedValues: string[];
    onChange?:(value: string[]) => void
}


const MultipleTextInput = ({ selectedValues, label, onChange }:InputRangeProps)=> {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setUpdateView(updateView+1)
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const [inputValue, setInputValue] = useState<string>('');
    const [values, setValues] = useState<string[]>(selectedValues);
    const [updateView, setUpdateView] = useState<number>(1);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && inputValue.trim()) {
            if (!values.includes(inputValue.trim())) setValues([...values, inputValue.trim()]);
            setInputValue('');
            event.preventDefault();
        }
    };

    const handleDelete = (valueToDelete: string) => {
        setValues(values.filter((value) => value !== valueToDelete));
    };

    useEffect(()=> {
        if (onChange) onChange(values)
    },[values])

    return   <>
            <div className={classes['input-options-selected']} onClick={handleClick}>
                {values.length > 0 ? values.join(',') : 'Seleccionar'}
            </div>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: {
                        overflow: 'visible', // Ensure no scrollbar appears on the Popover
                    },
                }}
            >
                <Box sx={{ p: 2, width: 300 }}>
                    <Box display="flex" flexWrap="wrap" gap={1}>
                        {values.map((value) => (
                            <Chip
                                key={value}
                                label={value}
                                onDelete={() => handleDelete(value)}
                                sx={{
                                    backgroundColor: '#f0f0f0',
                                    color: '#333',
                                }}
                            />
                        ))}
                    </Box>
                    <TextField
                        placeholder={label}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyPress={handleKeyPress}
                        variant="outlined"
                        multiline
                        size='small'
                        sx={{
                            borderRadius: '10px',
                            backgroundColor: '#F5F6FA',
                            height: '2.5vw',
                            marginTop: '5px',
                            fontFamily: 'Gilroy-Medium',
                            fontSize: '14px',
                            width: '100%',
                            border: '1px solid rgba(128, 128, 128, 0.282)',
                            '& .MuiOutlinedInput-root': {
                                fontSize: '14px',
                                fontFamily: 'Gilroy-Medium',
                                height: '100%',
                                '& fieldset': {
                                    borderColor: 'transparent',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'transparent',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'transparent',
                                },
                            },
                        }}
                        InputProps={{
                            endAdornment: inputValue != '' ? (
                                <IconButton
                                    onClick={(event) => {
                                        if (!values.includes(inputValue.trim())) setValues([...values, inputValue.trim()]);
                                        setInputValue('');
                                        event.preventDefault();
                                    }}
                                    edge="end"
                                >
                                    {NewsanIcons.ADD_OUTLINED}
                                </IconButton>
                            ): null,
                        }}
                    />
                </Box>
            </Popover>
    </>;
}


export default MultipleTextInput;