/* eslint-disable @typescript-eslint/no-explicit-any */
import { SvgIcon } from '@mui/material'
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined'
import { ReactComponent as StockIcon } from '../../assets/icons/stock-icon.svg'
import { ReactComponent as AnalysisIcon } from '../../assets/icons/analysis-icon.svg'
import { ReactComponent as MarketIcon } from '../../assets/icons/market-icon.svg'
import { ReactComponent as UserIcon } from '../../assets/icons/users-icon.svg'
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings-icon.svg'
import { ReactComponent as QuestionsIcon } from '../../assets/icons/questions-icon.svg'
import { ReactComponent as RefreshIcon } from '../../assets/icons/refresh-menu-icon.svg'
import { ReactComponent as RefreshOutlinedIcon } from '../../assets/icons/refresh-outlined-menu-icon.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete-menu-icon.svg'
import { ReactComponent as CloneIcon } from '../../assets/icons/clone-menu-icon.svg'
import { ReactComponent as PlusIcon } from '../../assets/icons/plus-menu-icon.svg'
import { ReactComponent as NotificationIcon } from '../../assets/icons/notification-icon.svg'
import { ReactComponent as AddFileIcon } from '../../assets/icons/add-file-icon.svg'
import { ReactComponent as CsvFileIcon } from '../../assets/icons/csv-icon.svg'
import { ReactComponent as XlsxFileIcon } from '../../assets/icons/xls-file-icon.svg'
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel-error-icon.svg'
import { ReactComponent as CancelIconOutlined } from '../../assets/icons/cancel-error-outlined-icon.svg'
import { ReactComponent as CheckIcon } from '../../assets/icons/check-icon.svg'
import { ReactComponent as WarningIcon } from '../../assets/icons/warning-icon.svg'
import { ReactComponent as InfoIcon } from '../../assets/icons/info-icon.svg'
import { ReactComponent as InfoOutlinedIcon } from '../../assets/icons/info-outlined-icon.svg'
import { ReactComponent as LockIcon } from '../../assets/icons/lock-icon.svg'
import { ReactComponent as StoreIcon } from '../../assets/icons/store-icon.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/search-icon.svg'
import { ReactComponent as AddStoreIcon } from '../../assets/icons/add-store-icon.svg'
import { ReactComponent as FilterIcon } from '../../assets/icons/filter-icon.svg'
import { ReactComponent as FilterFilledIcon } from '../../assets/icons/filter-filled-icon.svg'
import { ReactComponent as SummaryIcon } from '../../assets/icons/summary-icon.svg'
import { ReactComponent as SummarizeIcon } from '../../assets/icons/summarize-icon.svg'
import { ReactComponent as BookmarksIcon } from '../../assets/icons/bookmarks-icon.svg'
import { ReactComponent as DistributionCentresIcon } from '../../assets/icons/distribution-centres.svg'
import { ReactComponent as MarkMailReadIcon } from '../../assets/icons/mark-email-read-icon.svg'
import { ReactComponent as MarkMailUnreadIcon } from '../../assets/icons/mark-email-unread.svg'
import { ReactComponent as CopyIcon } from '../../assets/icons/copy-icon.svg'
import { ReactComponent as RestartIcon } from '../../assets/icons/restart.svg'
import { ReactComponent as HelpIcon } from '../../assets/icons/help-icon.svg'
import { ReactComponent as MassiveLoad } from '../../assets/icons/massive-load-icon.svg'
import { ReactComponent as AddOutlined } from '../../assets/icons/add-outlined-icon.svg'
import { ReactComponent as NewsanIcon } from '../../assets/icons/newsan-icon.svg'
import { ReactComponent as FileIcon } from '../../assets/icons/file-icon.svg'
import { ReactComponent as EditDocument } from '../../assets/icons/edit-document-icon.svg'
import { ReactComponent as WarehouseTypeIcon } from '../../assets/icons/warehouse-icon.svg'
import { ReactComponent as StoreTypeIcon } from '../../assets/icons/store-type-icon.svg'
import { ReactComponent as InventoryIcon } from '../../assets/icons/inventory-icon.svg'
import { ReactComponent as MonitorIcon } from '../../assets/icons/monitor-icon.svg'
import { ReactComponent as SettingsIcon2 } from '../../assets/icons/settings-icon-2.svg'

class NewsanIcons {
  public static STOCK: any = (<SvgIcon component={StockIcon} viewBox='0 0 18 18' />)
  public static ANALYSIS: any = (<SvgIcon component={AnalysisIcon} viewBox='0 0 18 18' />)
  public static WAREHOUSE: any = (<SvgIcon component={MarketIcon} viewBox='0 0 20 20' />)
  public static USERS: any = (<SvgIcon component={UserIcon} viewBox='0 0 20 20' />)
  public static SETTINGS: any = (<SvgIcon component={SettingsIcon} viewBox='0 0 20 20' />)
  public static QUESTIONS: any = (<SvgIcon component={QuestionsIcon} viewBox='0 0 20 20' />)
  public static REFRESH: any = (<SvgIcon component={RefreshIcon} viewBox='0 0 20 20' />)
  public static REFRESH_OUTLINED: any = (
    <SvgIcon component={RefreshOutlinedIcon} viewBox='0 -960 960 960' />
  )
  public static DELETE: any = (<SvgIcon component={DeleteIcon} viewBox='0 0 20 20' />)
  public static CLONE: any = (<SvgIcon component={CloneIcon} viewBox='0 0 20 20' />)
  public static PLUS: any = (<SvgIcon component={PlusIcon} viewBox='0 0 20 20' />)
  public static NOTIFICATION: any = (<SvgIcon component={NotificationIcon} viewBox='0 0 18 22' />)
  public static ADD_FILE: any = (<SvgIcon component={AddFileIcon} viewBox='0 0 74 87' />)
  public static CSV_FILE: any = (<SvgIcon component={CsvFileIcon} viewBox='0 0 48 50' />)
  public static XLSX_FILE: any = (<SvgIcon component={XlsxFileIcon} viewBox='0 0 24 24' />)
  public static CANCEL_ERROR: any = (<SvgIcon component={CancelIcon} viewBox='0 0 20 20' />)
  public static CANCEL_ERROR_OUTLINED: any = (
    <SvgIcon component={CancelIconOutlined} viewBox='0 -960 960 960' />
  )
  public static CHECK: any = (<SvgIcon component={CheckIcon} viewBox='0 0 20 20' />)
  public static WARNING: any = (<SvgIcon component={WarningIcon} viewBox='0 -960 960 960' />)
  public static INFO: any = (<SvgIcon component={InfoIcon} viewBox='0 0 14 14' />)
  public static INFO_OUTLINED: any = (
    <SvgIcon component={InfoOutlinedIcon} viewBox='0 -960 960 960' />
  )
  public static LOCK: any = (<SvgIcon component={LockIcon} viewBox='0 0 21 23' />)
  public static STORE: any = (<SvgIcon component={StoreIcon} viewBox='0 0 21 21' />)
  public static SEARCH: any = (<SvgIcon component={SearchIcon} viewBox='0 0 18 17' />)
  public static ADD_STORE: any = (<SvgIcon component={AddStoreIcon} viewBox='0 0 34 34' />)
  public static FILE: any = (<SvgIcon component={FileIcon} viewBox='0 -960 960 960' />)
  public static FILTER: any = (<SvgIcon component={FilterIcon} viewBox='0 0 12 14' />)
  public static FILTER_FILLED: any = (<SvgIcon component={FilterFilledIcon} viewBox='0 0 24 24' />)
  public static SUMMARY: any = (<SvgIcon component={SummaryIcon} viewBox='0 -960 960 960' />)
  public static SUMMARIZE: any = (<SvgIcon component={SummarizeIcon} viewBox='0 -960 960 960' />)
  public static BOOKMARKS: any = (<SvgIcon component={BookmarksIcon} viewBox='0 -960 960 960' />)
  public static DISTRIBUTION_CENTRES: any = (
    <SvgIcon component={DistributionCentresIcon} viewBox='0 -960 960 960' />
  )
  public static MAIL_AS_READ: any = (
    <SvgIcon component={MarkMailReadIcon} viewBox='0 -960 960 960' />
  )
  public static MAIL_AS_UNREAD: any = (
    <SvgIcon component={MarkMailUnreadIcon} viewBox='0 -960 960 960' />
  )
  public static COPY: any = (<SvgIcon component={CopyIcon} viewBox='0 -960 960 960' />)
  public static RESTART: any = (<SvgIcon component={RestartIcon} viewBox='0 -960 960 960' />)
  public static HELP: any = (<SvgIcon component={HelpIcon} viewBox='0 -960 960 960' />)
  public static MASSIVE_LOAD = (<SvgIcon component={MassiveLoad} viewBox='0 0 22 23' />)
  public static ADD_OUTLINED: any = (<SvgIcon component={AddOutlined} viewBox='0 -960 960 960' />)
  public static NEWSAN: any = (<SvgIcon component={NewsanIcon} viewBox='0 0 841.89 595.28' />)
  public static STOCK_TRHRESHOLD: any = (<SvgIcon component={SpeedOutlinedIcon} />)
  public static EDIT_DOCUMENT = (<SvgIcon component={EditDocument} viewBox='0 -960 960 960' />)
  public static WAREHOUSE_TYPE = (
    <SvgIcon component={WarehouseTypeIcon} viewBox='0 -960 960 960' />
  )
  public static STORE_TYPE = (<SvgIcon component={StoreTypeIcon} viewBox='0 -960 960 960' />)
  public static INVENTORY_ICON = (<SvgIcon component={InventoryIcon} viewBox='0 -960 960 960' />)
  public static SETTING_ICON = (<SvgIcon component={SettingsIcon2} viewBox='0 -960 960 960' />)
  public static MONITOR_ICON = (<SvgIcon component={MonitorIcon} viewBox='0 -960 960 960' />)
}

export default NewsanIcons
