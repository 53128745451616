/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
// import useData from '../../components/hooks/useData'
import Topbar from '../../components/common/topbar/Topbar'
import {
  CodeDescription,
  FilterTable,
  FilterTableType,
  MovementReport,
  ReservedType,
  TableAction,
  TableDetailCell,
  TableHeaderCell,
  Warehouse,
} from '../../model/models-module'
import { addParam, appendStringParam, formatParamDate } from '../../utils/CommonUtils'
import { useData } from '../../components/hooks/useData'
import DataTable from '../../components/common/datatable/Datatable'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Select,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import classes from './AuditReport.module.css'
import { GridDTO } from '../../model/dto-module'
import NewsanIcons from '../../components/common/Icons'
import InputDateRange from '../../components/common/input-date-range/InputDateRange'
import ButtonToCopy from '../../components/common/ButtonToCopy'
import AuditReportService from '../../services/AuditReportService'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import UserWarehousesService from '../../services/UserWarehousesService'
import {DropdownOptionsType} from '../../components/common/autocomplete-input/ConfigurableAutocompleteInput'
import { ReactComponent as CheckboxIcon } from '../../assets/icons/checkbox.svg'
import CustomAutoCompleteMultiple from '../../components/common/custom-autocomplete-multiple/CustomAutoCompleteMultiple'
import moment from 'moment'
import MultipleTextInput from '../../components/common/multiple-text-input/MultipleTextInput'

// const gridActions =
const AuditReport = () => {
  const MAXIMO_RESPUESTAS_TABLA: number = 1000
  const service: AuditReportService = AuditReportService.Instance
  const [headerActions, setHeaderActions] = useState<TableAction[]>()
  const [loadingHeader, setLoadingHeader] = useState<boolean>(true)
  const [loadingReprocess, setLoadingReprocess] = useState<boolean>(false)
  const [dataHeader, setDataHeader] = useState<TableHeaderCell[]>([])
  const [dataTo, setDataTo] = useState<number>(50)
  const [countItems, setCountItems] = useState<number>(0)
  const [dataOffset, setDataOffset] = useState<number>(0)
  const [movementTypeExcel, setMovementTypeExcel] = useState<string>('')
  const [typeExcel, setTypeExcel] = useState<string[]>([])
  const [movementType, setMovementType] = useState<string[]>()
  const [user, setUser] = useState<string>()
  const [userExcel, setUserExcel] = useState<string[]>()
  const [sku, setSku] = useState<string>()
  const [reserveType, setReserveType] = useState<string>()
  const [issueDateFrom, setIssueDateFrom] = useState<Date>()
  const [issueDateTo, setIssueDateTo] = useState<Date>()
  const [issueDateFromExcel, setIssueDateFromExcel] = useState<Date>()
  const [issueDateToExcel, setIssueDateToExcel] = useState<Date>()
  const [statusExcel, setStatusExcel] = useState<string>('')
  const [stateExcel, setStateExcel] = useState<string[]>([])
  const [warehouseExcel, setWarehouseExcel] = useState<string>('')
  const [warehouseArrayExcel, setWarehouseArrayExcel] = useState<string[]>([])
  const [status, setStatus] = useState<string[]>()
  const [distributionCentre, setDistributionCentre] = useState<string>()
  const [warehouse, setWarehouse] = useState<string[]>()
  const [skuArrayExcel, setSkuArrayExcel] = useState<string[]>([]);
  const [transactionId, setTransactionId] = useState<string>()
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)

  const [dataShow, setDataShow] = useState<TableDetailCell[]>([])
  const [page, setPage] = useState<number>(1)
  const [baseEndpoint, setBaseEndpoint] = useState<string>('')
  const [endpoint, setEndPoint] = useState<string>('')
  const [loading, error, dataGrid] = useData<GridDTO<MovementReport>>(baseEndpoint != '' ? endpoint : '', 'GET')
  const [params] = useState<Map<string, string>>(new Map())
  const [headParams] = useState<Map<string, string>>(new Map())

  const selectStyles = {
    anchorOrigin: {
      vertical: 'bottom' as const,
      horizontal: 'left' as const,
    },
    transformOrigin: {
      vertical: 'top' as const,
      horizontal: 'left' as const,
    },
    getContentAnchorEl: null,
    PaperProps: {
    sx: {
      maxHeight: '200px',
      borderRadius: '10px',
      '&::-webkit-scrollbar':{
        width: '5px',
        height: '5px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#c1c0c0'
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'var(--primary-color)',
      },
      '@media (max-width: 1366px)': {
        transform: 'scale(0.8) !important',
        transition: 'none !important'
      },
    },
  },
  sx: {
    '& .MuiMenuItem-root': {
      fontSize: '12px',
      padding: '4px 8px',
      position: 'relative',
      bottom: '0px'
    },
    '& .MuiMenuItem-root div': {
      position: 'relative',
      bottom: '4px'
    },
    '& .MuiMenuItem-root div span.check-label': {
      position: 'relative',
      top: '2px'
    },
  },}

  const [loadingReservedTypes, errorReservedTypes, dataGridReservedTypes] = useData<
    GridDTO<ReservedType>
  >('/reserved-types', 'GET')
  const [loadingDistributionCentres, errorDistributionCentres, dataGridDistributionCentres] =
    useData<GridDTO<CodeDescription>>('distribution-centres', 'GET')
  const [loadingWarehouses, errorWarehouses, dataWarehouses] = useData<GridDTO<Warehouse>>(
    '/warehouses',
    'GET',
  )
  const [loadingStates, errorStates, dataStates] = useData<string[]>(
    'warehouse-stock-movement-status',
    'GET',
  )
  const [loadingTypes, errorTypes, dataTypes] = useData<GridDTO<CodeDescription>>(
    'movements-types',
    'GET',
  )
  const [states, setStates] = useState<CodeDescription[]>([])
  const userWareHouseService: UserWarehousesService = UserWarehousesService.Instance

  useEffect(() => {
    setStates(
      dataStates?.map((rt, i) => {
        return { id: i + 1, code: rt, description: rt }
      }) ?? [],
    )
  }, [dataStates])

  const formatearFecha = (date: string) => {
    const fechaObj = new Date(date)
    const año = fechaObj.getFullYear()
    const mes = (fechaObj.getMonth() + 1).toString().padStart(2, '0')
    const dia = fechaObj.getDate().toString().padStart(2, '0')
    const hora = fechaObj.getHours().toString().padStart(2, '0')
    const minutos = fechaObj.getMinutes().toString().padStart(2, '0')

    return `${dia}/${mes}/${año} ${hora}:${minutos}`
  }

  if (
    error ||
    errorReservedTypes ||
    errorDistributionCentres ||
    errorWarehouses ||
    errorStates ||
    errorTypes
  ) {
    console.error(
      error,
      errorReservedTypes,
      errorDistributionCentres,
      errorWarehouses,
      errorStates,
      errorTypes,
    )
  }

  useEffect(() => {
    const fechaFormateada = formatParamDate(issueDateFromExcel)
    if (fechaFormateada) {
      headParams.set('issueDateFrom', appendStringParam(fechaFormateada, 'issueDateFrom'))
    } else {
      headParams.delete('issueDateFrom')
    }
  }, [issueDateFromExcel])

  useEffect(() => {
    const fechaFormateada = formatParamDate(issueDateToExcel)
    if (fechaFormateada) {
      headParams.set('issueDateTo', appendStringParam(fechaFormateada, 'issueDateTo'))
    } else {
      headParams.delete('issueDateTo')
    }
  }, [issueDateToExcel])

  useEffect(() => {
    if (userExcel) {
      headParams.set('creator', appendStringParam(userExcel.join(','), 'creator'))
    } else {
      headParams.delete('creator')
    }
  }, [userExcel])

  useEffect(() => {
    console.warn(skuArrayExcel)
    if (skuArrayExcel) {
      headParams.set('sku', appendStringParam(skuArrayExcel.join(','), 'sku'))
    } else {
      headParams.delete('sku')
    }
  }, [skuArrayExcel])

  useEffect(() => {
    if (movementTypeExcel) {
      const arr = movementTypeExcel.split(',')
      const matchingIds = dataTypes?.results
      .filter(item => item.code && arr.includes(item?.code))
      .map(item => item.id);
      const idsString = matchingIds ? matchingIds.join(',') : ''
      
      headParams.set('movementTypeId', appendStringParam(idsString, 'movementTypeId'))
    } else {
      headParams.delete('movementTypeId')
    }
  }, [movementTypeExcel])

  useEffect(() => {
    if (statusExcel) {
      headParams.set('status', appendStringParam(statusExcel, 'status'))
    } else {
      headParams.delete('status')
    }
  }, [statusExcel])

  useEffect(() => {
    if (warehouseExcel) {
      headParams.set('warehouse', appendStringParam(warehouseExcel, 'warehouse'))
    } else {
      headParams.delete('warehouse')
    }
  }, [warehouseExcel])

  useEffect(() => {
    addParam(dataTo, 'limit', params, baseEndpoint, setEndPoint)
  }, [dataTo, baseEndpoint])

  useEffect(() => {
    addParam(dataOffset, 'offset', params, baseEndpoint, setEndPoint)
  }, [dataOffset, baseEndpoint])

  useEffect(() => {
    setDataOffset(0)
    const fechaFormateada = formatParamDate(issueDateFrom)
    addParam(fechaFormateada, 'issueDateFrom', params, baseEndpoint, setEndPoint)
  }, [issueDateFrom, baseEndpoint])

  useEffect(() => {
    setDataOffset(0)
    const fechaFormateada = formatParamDate(issueDateTo)
    addParam(fechaFormateada, 'issueDateTo', params, baseEndpoint, setEndPoint)
  }, [issueDateTo, baseEndpoint])
  

  useEffect(() => {
    setDataOffset(0)
    addParam(sku, 'sku', params, baseEndpoint, setEndPoint)
  }, [sku, baseEndpoint])

  useEffect(() => {
    setDataOffset(0)
    addParam(reserveType, 'reserveType', params, baseEndpoint, setEndPoint)
  }, [reserveType, baseEndpoint])

  useEffect(() => {
      setDataOffset(0)
      addParam(movementType?.length ? movementType : '', 'movementTypeId', params, baseEndpoint, setEndPoint)
  }, [movementType, baseEndpoint])

  useEffect(() => {
    setDataOffset(0)
    addParam(distributionCentre, 'distributionCentre', params, baseEndpoint, setEndPoint)
  }, [distributionCentre, baseEndpoint])

  useEffect(() => {
      setDataOffset(0)
      addParam(warehouse?.length ? warehouse : '', 'warehouse', params, baseEndpoint, setEndPoint)
  }, [warehouse, baseEndpoint])

  useEffect(() => {
      setDataOffset(0)
      addParam(status?.length ? status : '', 'status', params, baseEndpoint, setEndPoint)
  }, [status, baseEndpoint])

  useEffect(() => {
    setDataOffset(0)
    addParam(transactionId, 'transactionId', params, baseEndpoint, setEndPoint)
  }, [transactionId, baseEndpoint])

  useEffect(() => {
    setDataOffset(0)
    addParam(user, 'creator', params, baseEndpoint, setEndPoint)
  }, [user, baseEndpoint])

  useEffect(() => {
    let filterLoadeed =
      !loadingReservedTypes && dataGridReservedTypes != null && dataHeader.length === 0
    filterLoadeed =
      filterLoadeed && !loadingDistributionCentres && dataGridDistributionCentres != null
    filterLoadeed = filterLoadeed && !loadingWarehouses && dataWarehouses?.results != null
    filterLoadeed = filterLoadeed && !loadingStates && dataStates != null
    filterLoadeed = filterLoadeed && !loadingTypes && dataTypes != null

    if (filterLoadeed) {
      setDataHeader([
        new TableHeaderCell({
          label: 'Fecha',
          key: 'date',
          sorteable: false,
          filter: new FilterTable({
            onChange: (newVal: any) => {
              if (newVal.from) setIssueDateFrom(newVal.from)
              if (newVal.to) setIssueDateTo(newVal.to)
            },
            value: {
              issueDateFrom,
              issueDateTo
            },
            type: FilterTableType.DATE
          }),
          headerStyle: { minWidth: '140px' },
          colStyle: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: '120px',
          },
        }),
        new TableHeaderCell({
          label: 'Usuario',
          key: 'user',
          sorteable: false,
          filter: new FilterTable({
            onChange: (newVal: any) => {
              setUser(newVal)
            },
            placeholder: 'Busca aqui...',
            value: user
          }),
        }),
        new TableHeaderCell({
          label: 'Transacción',
          key: 'transactionId',
          sorteable: false,
          filter: new FilterTable({
            value: transactionId,
            onChange: (newVal: any) => {
              setTransactionId(newVal)
            },
            placeholder: 'Busca aqui...',
          }),
          headerStyle: { minWidth: '160px' },
          colStyle: { maxWidth: '160px' },
        }),
        new TableHeaderCell({
          label: 'SKU',
          key: 'sku',
          sorteable: false,
          filter: new FilterTable({
            onChange: (newVal: any) => {
              setSku(newVal)
            },
            placeholder: 'Busca aqui',
            value: sku
          }),
        }),
        new TableHeaderCell({
          label: 'Estado',
          key: 'status',
          sorteable: false,
          headerStyle: { minWidth: '105px' },
          filter: new FilterTable({
            onChange: (newVal: any) => {
              setStatus(newVal)
            },
            useCodeField: true,
            data: dataStates!
              ? dataStates.map((rt, i) => {
                  return { id: i + 1, code: rt, description: rt }
                })
              : [],
            type: FilterTableType.MULTIPLE,
            value: status
          }),
        }),
        new TableHeaderCell({
          label: 'Tipo',
          key: 'movementType',
          sorteable: false,
          headerStyle: { minWidth: '85px' },
          filter: new FilterTable({
            onChange: (newVal: any) => {
              setMovementType(newVal)
            },
            useCodeField: false,
            data: dataTypes!
              ? dataTypes.results.map((rt) => {
                  return rt
                })
              : [],
            type: FilterTableType.MULTIPLE,
            value: movementType
          }),
        }),
        new TableHeaderCell({
          label: 'CD',
          key: 'distributionCentre',
          sorteable: false,
          filter: new FilterTable({
            onChange: (newVal: any) => {
              console.warn(newVal)
              setDistributionCentre(newVal)
            },
            useCodeField: true,
            data: dataGridDistributionCentres!
              ? dataGridDistributionCentres.results.map((rt) => {
                  rt.code = rt.description
                  return rt
                })
              : [],
            type: FilterTableType.COMBO,
          }),
        }),
        new TableHeaderCell({
          label: 'Tipo reserva',
          key: 'reserveType',
          sorteable: false,
          filter: new FilterTable({
            onChange: (newVal: any) => {
              setReserveType(newVal)
            },
            useCodeField: true,
            data: dataGridReservedTypes!.results!
              ? dataGridReservedTypes!.results.map((rt, i) => {
                  return { id: i + 1, code: rt.code, description: rt.code }
                })
              : [],
            type: FilterTableType.MULTIPLE,
          }),
          colStyle: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: '120px',
            position: 'relative',
            top: '0px',
          },
        }),
        new TableHeaderCell({
          label: 'Almacén',
          key: 'warehouse',
          sorteable: false,
          filter: new FilterTable({
            onChange: (newVal: any) => {
              setWarehouse(newVal)
            },
            useCodeField: true,
            data: dataWarehouses
              ? dataWarehouses.results.map((rt) => {
                  rt.code = rt.description
                  return rt
                })
              : [],
            type: FilterTableType.MULTIPLE,
            value: warehouse
          }),
          colStyle: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: '120px',
            position: 'relative',
            top: '0px',
          },
        }),
        new TableHeaderCell({
          label: '#Pre',
          key: 'stockMoveBefore',
          sorteable: false,
          headerStyle: {minWidth: '95px', textAlign: 'center' },
          colStyle: { textAlign: 'center' },
        }),
        new TableHeaderCell({
          label: '#Mov',
          key: 'quantity',
          sorteable: false,
          headerStyle: { minWidth: '95px', textAlign: 'center' },
          colStyle: { textAlign: 'center' },
        }),
        new TableHeaderCell({
          label: '#Post',
          key: 'stockMoveAfter',
          sorteable: false,
          headerStyle: { minWidth: '95px', textAlign: 'center' },
          colStyle: { textAlign: 'center' },
        }),
      ])
      setLoadingHeader(false)
    }
  }, [
    loadingReservedTypes,
    dataGridReservedTypes,
    dataHeader,
    loadingDistributionCentres,
    dataGridDistributionCentres,
    loadingWarehouses,
    dataWarehouses,
    loadingStates,
    dataStates,
  ])

  async function reloadTable() {
    if (issueDateFromExcel != undefined && issueDateToExcel != undefined){
      await setBaseEndpoint('')
      setBaseEndpoint('/audit-report')
    }
  }

  function filterByExcelFilters(){
    setIssueDateFrom(issueDateFromExcel)
    setIssueDateTo(issueDateToExcel)
    setUser(userExcel?.join(','))
    setStatus(stateExcel);
    setMovementType( () => {
      const arr = movementTypeExcel.split(',')
      const matchingIds = dataTypes?.results
      .filter(item => item.code && arr.includes(item?.code))
      .map(item => item.id.toString());
      return matchingIds
    })
    setWarehouse(warehouseArrayExcel)
    setSku(skuArrayExcel?.join(','))
    setDataHeader([])
    setDataShow([])
    setBaseEndpoint('/audit-report')
    setOpenErrorModal(false)
  }

  const handleSelectChange = (
    value: string | string[],
    setArrayState: React.Dispatch<React.SetStateAction<string[]>>,
    setStringState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    /* eslint-disable  */
    const selectedArray = typeof value === 'string' ? value.split(',') : value || []
    setArrayState(selectedArray)
    const concatenatedString = Array.isArray(selectedArray) ? selectedArray.join(',') : ''
    setStringState(concatenatedString)
  }

  useEffect(() => {
    if (!loading && dataGrid && dataGrid.count <= MAXIMO_RESPUESTAS_TABLA) {
      setOpenErrorModal(false)
      setCountItems(dataGrid.count)
      setDataShow(
        dataGrid.results.map((r) => {
          return new TableDetailCell(
            { ...r, date: formatearFecha(r.date), user: r.changedBy ?? r.createdBy },
            [],
            new Map<string, React.ReactNode>([
              [
                'sku',
                <div
                  key={r.sku + 'sku'}
                  className={classes['textWithCopy']}
                  style={{ minWidth: '115px' }}
                >
                  {r.sku! && r.sku.length < 10 ? (
                    <Typography fontSize={'0.875rem'} style={{ paddingRight: '40px' }}>
                      {r.sku}
                    </Typography>
                  ) : (
                    <Tooltip title={r.sku}>
                      <Typography
                        whiteSpace={'nowrap'}
                        textOverflow={'ellipsis'}
                        overflow={'hidden'}
                        fontSize={'0.875rem'}
                        maxWidth={85}
                      >
                        {r.sku}
                      </Typography>
                    </Tooltip>
                  )}
                  {r.sku != null ? (
                    <ButtonToCopy
                      text={r.sku}
                      style={{ position: 'absolute', right: '0px', top: '-10px' }}
                    ></ButtonToCopy>
                  ) : null}
                </div>,
              ],
              [
                'transactionId',
                <div
                  key={r.transactionId + 'transaction-id'}
                  className={classes['textWithCopy']}
                  style={{ maxWidth: '135px' }}
                >
                  {r.transactionId! && r.transactionId.length < 14 ? (
                    <Typography
                      fontSize={'0.875rem'}
                      whiteSpace={'nowrap'}
                      textOverflow={'ellipsis'}
                      overflow={'hidden'}
                    >
                      {r.transactionId}
                    </Typography>
                  ) : (
                    <Tooltip title={r.transactionId}>
                      <Typography
                        whiteSpace={'nowrap'}
                        textOverflow={'ellipsis'}
                        overflow={'hidden'}
                        fontSize={'0.875rem'}
                        maxWidth={105}
                      >
                        {r.transactionId}
                      </Typography>
                    </Tooltip>
                  )}
                  {r.transactionId != null ? (
                    <ButtonToCopy
                      text={r.transactionId}
                      style={{ position: 'absolute', right: '0px', top: '-10px' }}
                    ></ButtonToCopy>
                  ) : null}
                </div>,
              ],
              [
                'reserveType',
                r.reserveType! && r.reserveType.length < 14 ? (
                  <Typography
                    fontSize={'0.875rem'}
                    whiteSpace={'nowrap'}
                    textOverflow={'ellipsis'}
                    overflow={'hidden'}
                  >
                    {r.reserveType}
                  </Typography>
                ) : (
                  <Tooltip title={r.reserveType}>
                    <Typography
                      whiteSpace={'nowrap'}
                      textOverflow={'ellipsis'}
                      overflow={'hidden'}
                      fontSize={'0.875rem'}
                      maxWidth={105}
                    >
                      {r.reserveType}
                    </Typography>
                  </Tooltip>
                ),
              ],
              [
                'warehouse',
                r.warehouse! && r.warehouse.length < 14 ? (
                  <Typography
                    fontSize={'0.875rem'}
                    whiteSpace={'nowrap'}
                    textOverflow={'ellipsis'}
                    overflow={'hidden'}
                  >
                    {r.warehouse}
                  </Typography>
                ) : (
                  <Tooltip title={r.warehouse}>
                    <Typography
                      whiteSpace={'nowrap'}
                      textOverflow={'ellipsis'}
                      overflow={'hidden'}
                      fontSize={'0.875rem'}
                      maxWidth={105}
                    >
                      {r.warehouse}
                    </Typography>
                  </Tooltip>
                ),
              ],
            ]),
            classes['customRow'],
          )
        }),
      )
    } else if (dataGrid && dataGrid?.count > MAXIMO_RESPUESTAS_TABLA && baseEndpoint != ''){
      setOpenErrorModal(true)
    }
  }, [setDataShow, dataGrid, loading])

  const onChangePageSize = useCallback((size: number) => {
    setDataTo(size)
    setDataOffset(0)
    setPage(1)
  }, [])
  const onChangePage = useCallback((page: number) => {
    setPage(page)
    setDataOffset(page - 1)
  }, [])

  function cleanFilters(){
    setBaseEndpoint('')
    setUser(undefined)
    setIssueDateFrom(undefined)
    setIssueDateTo(undefined)
    setMovementType([])
    setStatus([])
    setWarehouse([])
    setDataHeader([])
    setDataShow([])
  }

  useMemo(() => {
    const downloadGrid = new TableAction({
      label: 'Descargar registros',
      action: () => {
        setLoadingReprocess(true)
        const filteredParams = new Map(
          Array.from(params.entries()).filter(([key]) => key !== 'limit')
        );
        service
          .generateGridReport(filteredParams)
          .then((data: any) => {
            const blob = new Blob([data])
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = `auditoria_${moment(new Date()).format('YYYYMMDDHHmm')}.xlsx`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)
          })
          .finally(() => {
            setLoadingReprocess(false)
          })
      },
      icon: <FileDownloadOutlinedIcon></FileDownloadOutlinedIcon>,
    })

    setHeaderActions([downloadGrid])
  }, [])

  const handleUserOptionChange = (value: DropdownOptionsType | DropdownOptionsType[] | null ) => {
    setUserExcel(Array.isArray(value)  ? value?.map((v) =>v.email ?? '') : [])
  }

  return (
    <div className={classes.auditReportPage}>
      {loading || loadingHeader || loadingReprocess ? (
        <div className='loadingPage'>
          <CircularProgress className='circleProgress'/>
      </div>
      ) : null}
      <Topbar title={'Reporte de auditoría'} subtitle={''} hasReloadIcon reloadPage={reloadTable} />
      <div className={classes['panel-filters']}>
        <Box className={classes['filter-row']}>
          <Box className={classes['filter-box']}>
            <label htmlFor=''>Fecha</label>
            <InputDateRange
              valueFrom={issueDateFromExcel}
              valueTo={issueDateToExcel}
              onChange={(newVal) => {
                setIssueDateFromExcel(newVal.from)
                setIssueDateToExcel(newVal.to)
              }}
              bothRequired={true}
            ></InputDateRange>
          </Box>
          <Box className={classes['filter-box']}>
            <label htmlFor=''>Usuario</label>
            <CustomAutoCompleteMultiple
              users={userExcel ?? []}
              autocompleteProps={{
                name:'username',
                placeholder: 'Ingrese un usuario',
                label: '',
                cantidadCaracteres: 3,
                service: userWareHouseService.getAllAsDropdownAutocomplete.bind(userWareHouseService),
                handleOptionChange: handleUserOptionChange
              }}
            />
          </Box>
          <Box className={classes['filter-box']}>
            <label htmlFor="">SKU</label>
            <MultipleTextInput 
              label='Ingrese un sku'
              selectedValues={skuArrayExcel} 
              onChange={(v) =>{
                setSkuArrayExcel(v)
              }}
            />
          </Box>
          <Box className={classes['filter-box']}>
            <label htmlFor=''>Tipo</label>
            <FormControl className={classes['select-filter']} size='small'>
              <Select
                value={typeExcel || []}
                multiple
                onChange={(e) =>
                  {
                    handleSelectChange(e.target.value, setTypeExcel, setMovementTypeExcel)}
                  }
                renderValue={(selected) => (selected as string[]).join(', ')}
                MenuProps={selectStyles}
              >
                {dataTypes?.results.map((e) => (
                  <MenuItem
                    className={classes['select-option']}
                    key={e.id}
                    value={e.code}
                  >
                    <div
                      key={`${e.id}-container`}
                      className='check-list-container'
                    >
                      <span
                        className={'check-container'}
                        key={`${e.id}-check-container`}
                      >
                        <SvgIcon
                          key={`${e.id}-check`}
                          className={
                            typeExcel.includes(e.code ?? '') ? ' checked'
                              : 'unchecked'
                          }
                          component={CheckboxIcon}
                          viewBox='0 0 18 22'
                        />
                      </span>
                      <span
                        className={classes['check-label']}
                        key={`${e.id}-check-label`}
                      >
                        {e.description}
                      </span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
              {typeExcel.length > 0 && (
                <IconButton
                  onClick={()=> {
                    setTypeExcel([]);
                    setMovementTypeExcel('[]');
                  }}
                  edge="end"
                  size="small"
                  sx={{
                    position: 'absolute',
                    right: 30, // Ajusta para ubicarlo cerca de la flecha
                    top: '50%',
                    transform: 'translateY(-50%)'
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </FormControl>
          </Box>
          <Box className={classes['filter-box']}>
            <label htmlFor=''>Estado</label>
            <FormControl className={classes['select-filter']} size='small'>
              <Select
                value={stateExcel || []}
                multiple
                onChange={(e) =>
                  { 
                    /* eslint-disable  */
                    handleSelectChange(e.target.value, setStateExcel, setStatusExcel)}
  
                  }
                renderValue={(selected) => (selected as string[]).join(', ')}
                MenuProps={selectStyles}
              >
                {states?.map((e) => (
                  <MenuItem
                    className={classes['select-option']}
                    key={e.id}
                    value={e.code}
                  >
                    <div
                      key={`${e.id}-container`}
                      className='check-list-container'
                    >
                      <span
                        className={'check-container'}
                        key={`${e.id}-check-container`}
                      >
                        <SvgIcon
                          key={`${e.id}-check`}
                          className={
                            stateExcel.includes(e.code ?? '') ? ' checked'
                              : 'unchecked'
                          }
                          component={CheckboxIcon}
                          viewBox='0 0 18 22'
                        />
                      </span>
                      <span
                        className={classes['check-label']}
                        key={`${e.id}-check-label`}
                      >
                        {e.description}
                      </span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
              {stateExcel.length > 0 && (
                <IconButton
                  onClick={()=> {
                    setStateExcel([]);
                    setStatusExcel('')
                  }}
                  edge="end"
                  size="small"
                  sx={{
                    position: 'absolute',
                    right: 30, // Ajusta para ubicarlo cerca de la flecha
                    top: '50%',
                    transform: 'translateY(-50%)'
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </FormControl>
          </Box>
          <Box className={classes['filter-box']}>
            <label htmlFor=''>Almacén</label>
            <FormControl className={classes['select-filter']} size='small'>
              <Select
                value={warehouseArrayExcel || []}
                multiple
                onChange={(e) =>
                  { 
                    /* eslint-disable  */
                    handleSelectChange(e.target.value, setWarehouseArrayExcel, setWarehouseExcel)}
  
                  }
                renderValue={(selected) => (selected as string[]).join(', ')}
                MenuProps={selectStyles}
              >
                {dataWarehouses?.results.map((e) => (
                  <MenuItem
                    className={classes['select-option']}
                    key={e.id}
                    value={e.code}
                  >
                    <div
                      key={`${e.id}-container`}
                      className='check-list-container'
                    >
                      <span
                        className={'check-container'}
                        key={`${e.id}-check-container`}
                      >
                        <SvgIcon
                          key={`${e.id}-check`}
                          className={
                            warehouseArrayExcel.includes(e.code ?? '') ? ' checked'
                              : 'unchecked'
                          }
                          component={CheckboxIcon}
                          viewBox='0 0 18 22'
                        />
                      </span>
                      <span
                        className={classes['check-label']}
                        key={`${e.id}-check-label`}
                      >
                        {e.description}
                      </span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
              {warehouseArrayExcel.length > 0 && (
                <IconButton
                  onClick={()=> {
                    setWarehouseArrayExcel([]);
                    setWarehouseExcel('');
                  }}
                  edge="end"
                  size="small"
                  sx={{
                    position: 'absolute',
                    right: 30,
                    top: '50%',
                    transform: 'translateY(-50%)'
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </FormControl>
          </Box>
          <Button
            variant='contained'
            className='button-newsan-grey'
            onClick={filterByExcelFilters}
            disabled={!issueDateFromExcel && !issueDateToExcel }
          >
            <span className={classes.searchIcon}>{NewsanIcons.SEARCH}</span> Buscar
          </Button>
          <Button
            variant='contained'
            className='button-newsan-red'
            style={{
              whiteSpace: 'nowrap',
              gap: '3px'
            }}
            onClick={() => {
              setLoadingReprocess(true)
              service
                .generateHeaderReport(headParams)
                .then((data: any) => {
                  const blob = new Blob([data])
                  const url = window.URL.createObjectURL(blob)
                  const a = document.createElement('a')
                  a.href = url
                  a.download = `auditoria_${moment(new Date()).format('YYYYMMDDHHmm')}.xlsx`
                  document.body.appendChild(a)
                  a.click()
                  document.body.removeChild(a)
                  window.URL.revokeObjectURL(url)
                })
                .finally(() => {
                  setLoadingReprocess(false)
                })
            }}
            disabled={!issueDateFromExcel && !issueDateToExcel}
          >
            <FileDownloadOutlinedIcon></FileDownloadOutlinedIcon> Generar Reporte
          </Button>
        </Box>
      </div>
      <div className={classes['container-abm-page']}>
        <div className={classes['table-templates-container']}>
          {dataShow.length > 0 ? (
            <DataTable
              headerGroupActionsIcon={NewsanIcons.PLUS}
              headerActions={headerActions}
              headers={dataHeader}
              rows={dataShow}
              countItems={countItems}
              rowsPerPage={dataTo}
              hasActions={true}
              appendOnScrollToBottom={false}
              onPageSizeChange={onChangePageSize}
              onPageChange={onChangePage}
              pageSelected={page}
              pageable={true}
              selectable={false}
            ></DataTable>
          ) : null}
        </div>
      </div>
      <></>
      {
          openErrorModal && baseEndpoint != '' ? (
          <Modal 
            open={openErrorModal} 
            onClose={()=>{
              setOpenErrorModal(false)
              cleanFilters()
            }} 
            aria-labelledby="help-modal-title"
            aria-describedby="help-modal-description">
              <div className={classes['errorModal']}>
                <Typography id="modal-modal-title" style={{textAlign: 'justify'}}>
                La búsqueda seleccionada supera la cantidad de registros máximos, no se puede mostrar en la grilla. Deberá generar un reporte.
                </Typography>
                <Button onClick={()=>{
                    setOpenErrorModal(false)
                    cleanFilters()
                  }}>
                  Cerrar
                </Button>
              </div>
          </Modal >
          )
      :
      <></>
      }
    </div>
  )
}

export default AuditReport
