/* eslint-disable */
import { Autocomplete, TextField, CircularProgress, Paper, PaperProps, Chip } from '@mui/material'
import { useEffect, useState } from 'react'

type AutocompleteInputProps = {
  name: string
  placeholder?: string
  label: string
  value?: DropdownOptionsType | null // Para manejar el valor seleccionado
  onChange?: (value: DropdownOptionsType | DropdownOptionsType[] | null) => void // Callback para el cambio de valor
  service?: (searchCriteria: string) => Promise<{ data: DropdownOptionsType[] }>
  onOptionSelect?: () => void
  onClear?: () => void
  cantidadCaracteres?: number
  defaultOptions?: DropdownOptionsType[]
  PopperComponent?: (props: PaperProps) => JSX.Element | undefined
  autoCompleteSx?: object
  paperComponent?: object
  textFieldSx?: object
  className?: string
  multiple?: boolean
  menuStyles?: object
  showComponents?: boolean
}

export type DropdownOptionsType = {
  id?: number
  value: string | number
  label: string,
  username?: string,
  firstName?: string,
  lastName?: string,
  email?: string
}

const DefaultPaper = (props: any) => (
  <Paper
    {...props}
    sx={{
      width: '1%', // Cambia el ancho del menú según lo necesites
      maxHeight: '200px', // Limita la altura del menú
      overflow: 'auto',
      fontSize: '11px',
      padding: '5px',
      boxShadow: 0,
      '.MuiPaper-elevation': {
        fontSize: '11px'
      }
    }}
  />
)

const ConfigurableAutocompleteInput: React.FC<AutocompleteInputProps> = ({
  name,
  placeholder,
  label,
  multiple = false,
  value = multiple ? [] : null, // Valor inicial como array para múltiples opciones
  onChange,
  service,
  onOptionSelect,
  onClear,
  cantidadCaracteres = 0,
  defaultOptions = [],
  autoCompleteSx,
  paperComponent,
  textFieldSx,
  className = '',
  menuStyles = {},
  showComponents = true,
  ...props
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<DropdownOptionsType[]>(defaultOptions);
  const [inputValue, setInputValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (inputValue.length >= cantidadCaracteres) {
      if (service && searchValue !== inputValue) {
        let active = true;
        setSearchValue(inputValue);
        setLoading(true);

        const fetchOptions = async () => {
          try {
            const response = await service(inputValue);
            if (response.data && response.data.length > 0) {
              setOptions(response.data);
            } else {
              setOptions(defaultOptions);
            }
          } catch (error) {
            setOptions(defaultOptions);
            console.error('Error fetching options:', error);
          } finally {
            if (active) setLoading(false);
          }
        };

        fetchOptions();
      }
    } else {
      setOptions(defaultOptions);
    }
  }, [inputValue, cantidadCaracteres, defaultOptions, service, searchValue]);

  return (
    <Autocomplete
      {...props}
      className={className} 
      sx={{
        '& .MuiOutlinedInput-root': {
          border: 'none',
          '& fieldset': {
            border: 'none',
          },
          '&:hover fieldset': {
            border: 'none',
          },
          '& input': {
            border: '1px solid rgba(128, 128, 128, 0.282)'
          }
        },
        ...autoCompleteSx
      }}
      open={open}
      disablePortal={false}
      PopperComponent={open ? DefaultPaper : undefined}
      options={options}
      loading={loading}
      multiple={multiple}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onInputChange={(event, newInputValue, reason) => {
        setInputValue(newInputValue || '');
        if (reason === 'clear') {
          if (onChange) onChange(null)
          setOpen(false)
          if (onClear) {
            onClear();
          }
        }
      }}
      onOpen={() => {
        if (inputValue.length >= (cantidadCaracteres ? cantidadCaracteres : 0)) {
          setOpen(true);
        }else{
          setOpen(false)
        }
      }}
      onClose={() => {
        setOpen(false);
        setOptions(defaultOptions);
        setSearchValue('');
      }}
      onChange={(event, newValue) => {
        if (onChange) {
          onChange(newValue);
        }
        if (onOptionSelect && newValue) {
          onOptionSelect();
          setOpen(false)
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          label={label ?? ''}
          size="small"
          error={!value && Boolean(value)}
          sx={{
            fontFamily: 'Gilroy-Medium',
            fontSize: '14px',
            ...textFieldSx,
            '& .MuiInputBase-input::placeholder': {
              color: 'rgba(0, 0, 0, 1)',
              fontFamily: 'Gilroy-Medium',
              fontSize: '14px',
            },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      componentsProps={{
        clearIndicator: showComponents ? { title: 'Borrar' } : {sx: { display: 'none' }},
        popupIndicator: showComponents ? { title: 'Abrir' } : {sx: { display: 'none' }},
      }}
    />
  );
};


export default ConfigurableAutocompleteInput