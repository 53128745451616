/* eslint-disable @typescript-eslint/no-explicit-any */
import { SvgIcon } from '@mui/material';
import {ReactComponent as Character} from '../../assets/images/character.svg';
import {ReactComponent as CharacterRate} from '../../assets/images/rate-character.svg';
import {ReactComponent as SalesRateAnalytics} from '../../assets/images/analytics-sales-rate.svg';
import {ReactComponent as AvailableCharacter} from '../../assets/images/available-character.svg';



class NewsanPics {
    public static  CHARACTER: any = <SvgIcon component={Character} viewBox="0 0 127 238" />; 
    public static  CHARACTER_RATE_SCREEN: any = <SvgIcon component={CharacterRate} viewBox="0 0 178 197" />;
    public static  SALES_RATE_ANALYTICS: any = <SvgIcon component={SalesRateAnalytics} viewBox="0 0 678.62 365.87" />;
    public static AVAILABLE_CHARACTER: any = <SvgIcon component={AvailableCharacter} viewBox="0 0 552.81023 515.45882"/>;

}



export default NewsanPics;
