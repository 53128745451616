/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import axios from 'axios'
import classes from './DistributionCentres.module.css'

import { Button, CircularProgress, FormControl, MenuItem, Select } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'

import Topbar from '../../components/common/topbar/Topbar'
import DataTable from '../../components/common/datatable/Datatable'
import NewsanIcons from '../../components/common/Icons'
import PopupSuccessError from '../../components/common/popup-success-error/PopupSuccessError'

import {
  FilterTable,
  FilterTableType,
  DistributionCentre,
  TableAction,
  TableDetailCell,
  TableHeaderCell,
} from '../../model/models-module'
import { GridDTO } from '../../model/dto-module'

import AxiosInterceptor from '../../services/http/AxiosInterceptor'
import { useData } from '../../components/hooks/useData'

import { addParam } from '../../utils/CommonUtils'

type typeBody = {
  description: string
  code: string
  externalCode: number
  status: boolean
}

const client = AxiosInterceptor.addInterceptor(process.env.REACT_APP_API_BASE_URL)

const DistributionCentres = () => {
  const [dataShow, setDataShow] = useState<any>([])
  const [dataTo, setDataTo] = useState<number>(20)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [showForm, setShowForm] = useState<boolean>(false)
  const [newEditItem, setNewEditItem] = useState<DistributionCentre | undefined>()
  const [countItems, setCountItems] = useState<number>(0)
  const [dataOffset, setDataOffset] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [baseEndpoint, setBaseEndpoint] = useState<string>('/distribution-centres')
  const [distributionCentresSettingsEndpoint, setDistributionCentresEndpoint] = useState<string>('')

  const [loadingDistributionCentres, errorDistributionCentres, dataDistributionCentres] = useData<
    GridDTO<DistributionCentre>
  >(baseEndpoint != '' ? distributionCentresSettingsEndpoint : '/distribution-centres', 'GET')

  // Estados formulario
  const [selectedCode, setSelectedCode] = useState<string | undefined>('')
  const [selectedDescription, setSelectedDescription] = useState<string | undefined>('')
  const [selectedExternalCode, setSelectedExternalCode] = useState<number | undefined>(undefined)
  const [selectedStatus, setSelectedStatus] = useState<boolean | undefined>(undefined)

  const [sortArg, setSortArg] = useState<string>('')
  const [newSort, setNewSort] = useState<number>(0)
  const [sort] = useState<Map<string, string>>(new Map())
  const [params] = useState<Map<string, string>>(new Map())
  const [loadingResponse, setLoadingResponse] = useState(false)
  const [errorResponse, setErrorResponse] = useState<string[] | null>(null)
  const [dataResponse, setDataResponse] = useState<string | null>(null)

  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [dataHeader, setDataHeader] = useState<TableHeaderCell[]>([])
  const [descriptionFilter, setDescriptionFilter] = useState<string>()
  const [statusFilter, setStatusFilter] = useState<string>()

  //
  const fetchData = async (body?: typeBody) => {
    try {
      setLoadingResponse(true)
      if (isEdit) {
        await client.patch(`/distribution-centres/${newEditItem?.id}`, body)
        setDataResponse('Modificado con éxito!')
        setErrorResponse(null)
        setIsEdit(false)
        setNewEditItem(undefined)
      } else {
        await client.post('/distribution-centres', body)
        setDataResponse('Creado con éxito')
        setErrorResponse(null)
      }
      formatForm()
      setShowForm(false)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setErrorResponse(error.response?.data.message)
      } else if (error instanceof Error) {
        setErrorResponse([error.message])
      } else {
        setErrorResponse(['Hubo un error en la solicitud'])
      }
    } finally {
      setLoadingResponse(false)
    }
  }

  const formatForm = () => {
    setSelectedCode('')
    setSelectedDescription('')
    setSelectedExternalCode(undefined)
    setSelectedStatus(undefined)
  }

  // funcion utiliza fetchData para hacer patch o post desde el form.
  const saveFormInfo = () => {
    // Validar campos del formulario
    if (
      !selectedCode ||
      !selectedDescription ||
      selectedStatus == null ||
      undefined ||
      selectedExternalCode == null ||
      undefined
    ) {
      alert('Se deben completar todos los campos')
    } else {
      const temporalObject: typeBody = {
        code: selectedCode,
        description: selectedDescription,
        externalCode: selectedExternalCode,
        status: selectedStatus,
      }
      setShowPopup(true)
      fetchData(temporalObject)
    }
  }

  const deleteTemplateConfig = async (id: number) => {
    try {
      setLoadingResponse(true)
      await client.delete(`/distribution-centres/${id}`)
      setDataResponse('Eliminado con éxito!')
      setErrorResponse(null)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setErrorResponse(error.response?.data.message)
      } else if (error instanceof Error) {
        setErrorResponse([error.message])
      } else {
        setErrorResponse(['Hubo un error en la solicitud'])
      }
    } finally {
      setLoadingResponse(false)
    }
  }

  useEffect(() => {
    addParam(dataTo, 'limit', params, baseEndpoint, setDistributionCentresEndpoint)
  }, [dataTo])

  useEffect(() => {
    addParam(dataOffset, 'offset', params, baseEndpoint, setDistributionCentresEndpoint)
  }, [dataOffset])

  useEffect(() => {
    setDataOffset(0)
    setDataShow([])
    addParam(sortArg, 'sort', params, baseEndpoint, setDistributionCentresEndpoint)
  }, [sortArg])

  //   useEffect(() => {
  //     addParam(true, 'allStatus', params, baseEndpoint, setDistributionCentresEndpoint)
  //   }, [])

  useEffect(() => {
    addParam(descriptionFilter, 'description', params, baseEndpoint, setDistributionCentresEndpoint)
  }, [descriptionFilter, baseEndpoint])

  useEffect(() => {
    addParam(statusFilter, 'status', params, baseEndpoint, setDistributionCentresEndpoint)
  }, [statusFilter, baseEndpoint])

  useEffect(() => {
    if (baseEndpoint == '') setBaseEndpoint('/distribution-centres')

    if (!errorDistributionCentres && dataDistributionCentres) {
      // eslint-disable-next-line no-console
      console.log(dataDistributionCentres)
      setCountItems(dataDistributionCentres.count)
      setDataShow(
        dataDistributionCentres.results.map((centre: DistributionCentre) => {
          const actions: TableAction[] = []
            actions.push(
              new TableAction({
                label: 'Editar',
                action: () => {
                  formatForm()
                  setNewEditItem(centre)
                  setIsEdit(true)
                  setShowForm(true)
                },
                icon: (
                  <span className={classes.editIcon}>
                    <EditIcon></EditIcon>
                  </span>
                ),
              }),
            )
            actions.push(
              new TableAction({
                label: 'Eliminar',
                action: async () => {
                  setShowPopup(true)
                  await deleteTemplateConfig(centre.id)
                },
                icon: <span>{NewsanIcons.DELETE}</span>,
              }),
            )
          return new TableDetailCell(
            { ...centre, status: centre.status ? 'Activo' : 'Inactivo' },
            actions,
          )
        }),
      )
    }
  }, [
    setDataShow,
    setCountItems,
    loadingDistributionCentres,
    dataDistributionCentres,
    dataTo,
    dataOffset,
    distributionCentresSettingsEndpoint,
  ])

  useEffect(() => {
    const filterLoaded =
      !loadingDistributionCentres && dataDistributionCentres != null && dataHeader.length === 0

    if (filterLoaded) {
      setDataHeader([
        new TableHeaderCell({
          label: 'Descripción',
          key: 'description',
          sorteable: true,
          colStyle: { paddingLeft: '33px' },
          filter: new FilterTable({
            onChange: (newVal) => {
              setDescriptionFilter(newVal)
            },
            placeholder: 'Busca aqui...',
          }),
        }),
        new TableHeaderCell({
          label: 'Estado',
          key: 'status',
          sorteable: true,
          colStyle: { paddingLeft: '34px' },
          filter: new FilterTable({
            onChange: (newVal) => {
              setStatusFilter(newVal)
            },
            type: FilterTableType.COMBO,
            data: [
              { id: 0, code: 'false', description: 'Inactivo' },
              { id: 1, code: 'true', description: 'Activo' },
            ],
            useCodeField: true,
          }),
        }),
      ])
    }
  })

  useEffect(() => {
    if (newEditItem && isEdit) {
      setSelectedCode(newEditItem.code)
      setSelectedDescription(newEditItem.description)
      setSelectedStatus(newEditItem.status)
      setSelectedExternalCode(newEditItem.externalCode)
    }
  }, [loadingDistributionCentres, newEditItem, isEdit])

  useEffect(() => {
    if (newSort > 0) {
      let args = ''
      sort.forEach((value) => {
        if (args === '') {
          args += value
        } else {
          args += `,${value}`
        }
      })
      setSortArg(args)
      setNewSort(0)
    }
  }, [newSort, setSortArg, setNewSort])

  const onSort = (key: string, order: string) => {
    if (order === '') {
      sort.delete(key)
    } else {
      sort.set(key, `${key}:${order}`)
    }
    setNewSort(1)
  }

  function reloadTable() {
    setBaseEndpoint('')
  }

  const handleCodeChange = (event: any) => {
    const value = Math.max(1, event.target.value);
    event.target.value = value;
    setSelectedExternalCode(value)
  };

  return (
    <div className={classes.distributionCentresPage}>
      {loadingDistributionCentres ? (
        <div className='loadingPage'>
          <CircularProgress className='circleProgress' />
        </div>
      ) : null}
      <Topbar
        title={'Centros de distribución'}
        subtitle={''}
        hasReloadIcon
        reloadPage={reloadTable}
      />
      <div className={classes['container-abm-page']}>
        {errorDistributionCentres ? (
          <>Ha habido un problema!</>
        ) : (
          <div
            className={
              showForm
                ? classes['table-templates-container-form-visible']
                : classes['table-templates-container']
            }
          >
            <DataTable
              headers={dataHeader}
              rows={dataShow}
              countItems={countItems}
              rowsPerPage={dataTo}
              hasActions={true}
              appendOnScrollToBottom={false}
              onPageSizeChange={(size) => {
                setDataTo(size)
                setDataOffset(0)
                setPage(1)
              }}
              onPageChange={(page: number) => {
                setPage(page)
                setDataOffset(page - 1)
              }}
              pageSelected={page}
              pageable={true}
              selectable={false}
              onSortChange={onSort}
              className={classes.cdTable}
            ></DataTable>
          </div>
        )}
          {showForm ? (
            <div className={classes['abm-container']}>
              <div
                className={isEdit ? classes['edit-form-container'] : classes['add-form-container']}
              >
                <span className={classes['title-form']}>
                  {NewsanIcons.ADD_STORE}{' '}
                  <span className={classes['title-form-text']}>
                    {isEdit ? 'Editar' : 'Agregar'}
                  </span>
                </span>
                <CloseIcon
                  className={classes['form-close-button']}
                  onClick={() => {
                    formatForm()
                    setShowForm(false)
                    setIsEdit(false)
                    setNewEditItem(undefined)
                  }}
                ></CloseIcon>

                <span className={classes['setting-attr-title']}>Nombre del Centro de distribución*</span>
                <input
                  type='text'
                  className={classes['setting-attr-input']}
                  placeholder='Descripción'
                  defaultValue={selectedDescription}
                  onChange={(e) => {
                    setSelectedDescription(e.target.value)
                  }}
                />

                <span className={classes['setting-attr-title']}>Código del Centro de distribución*</span>
                <input
                  type='text'
                  className={classes['setting-attr-input']}
                  placeholder='Código'
                  defaultValue={selectedCode}
                  onChange={(e) => {
                    setSelectedCode(e.target.value)
                  }}
                />

                <span className={classes['setting-attr-title']}>Código externo EBS*</span>
                <input
                  placeholder='Código externo'
                  defaultValue={selectedExternalCode}
                  type="number"
                  min={1}
                  onInput={handleCodeChange}
                  className={classes['setting-attr-input']}
                />

                <span className={classes['setting-attr-title']}>Estado*</span>
                <FormControl className={classes['select-filter']} size='small'>
                  <Select
                    value={selectedStatus ? 'Activo' : selectedStatus == false ? 'Inactivo' : ''}
                    onChange={(e) => {
                      if (e.target.value == 'Activo') {
                        setSelectedStatus(true)
                      } else if (e.target.value == 'Inactivo') {
                        setSelectedStatus(false)
                      }
                    }}
                  >
                    <MenuItem value={''}>
                      <em className={classes['no-selection']}>Seleccionar</em>
                    </MenuItem>
                    <MenuItem className={classes['select-option']} key={'Activo'} value={'Activo'}>
                      Activo
                    </MenuItem>
                    <MenuItem
                      className={classes['select-option']}
                      key={'Inactivo'}
                      value={'Inactivo'}
                    >
                      Inactivo
                    </MenuItem>
                  </Select>
                </FormControl>

                <div className={classes['buttons-container']}>
                  <Button
                    variant='contained'
                    className={classes['btn-newsan-cancel']}
                    onClick={() => {
                      setShowForm(false)
                      setIsEdit(false)
                      setNewEditItem(undefined)
                      setDataResponse(null)
                      setErrorResponse(null)
                      formatForm()
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant='contained'
                    onClick={async () => {
                      await saveFormInfo()
                    }}
                    className={
                      isEdit ? classes['button-newsan-red'] : classes['button-newsan-grey']
                    }
                  >
                    {isEdit ? 'Guardar' : 'Cargar'}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <span className={classes['btn-show-form']} onClick={() => setShowForm(!showForm)}>
              {NewsanIcons.ADD_STORE}
            </span>
          )}
      </div>
      {showPopup ? (
        <PopupSuccessError
          open={showPopup}
          onClose={() => {
            setShowPopup(false)
            setDataResponse(null)
            setBaseEndpoint(errorResponse == null ? '' : baseEndpoint)
            setErrorResponse(null)
          }}
          loading={loadingResponse}
          success={dataResponse !== null}
          successMessage={dataResponse}
          errorMessage={errorResponse}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default DistributionCentres
