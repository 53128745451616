/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, CircularProgress, FormControl, MenuItem, Select } from '@mui/material'
import Topbar from '../../components/common/topbar/Topbar'
import DataTable from '../../components/common/datatable/Datatable'
import { useEffect, useMemo, useState } from 'react'
import NewsanIcons from '../../components/common/Icons'
import classes from './ReservedTypes.module.css'
import { CodeDescription, FilterTable, FilterTableType, ReservedType, TableAction, TableDetailCell, TableHeaderCell} from '../../model/models-module'
import CloseIcon from '@mui/icons-material/Close'
import {useData} from '../../components/hooks/useData'
import axios from 'axios';
import AxiosInterceptor from '../../services/http/AxiosInterceptor';
import PopupSuccessError from '../../components/common/popup-success-error/PopupSuccessError'
import EditIcon from '@mui/icons-material/Edit';
import { GridDTO } from '../../model/dto-module'
import { addParam } from '../../utils/CommonUtils'
import ReserveTypeService from '../../services/ReserveTypeService'


  
type typeBody = {
  description: string,
  code: string,
  type: string,
  status: boolean,
}

const client = AxiosInterceptor.addInterceptor(process.env.REACT_APP_API_BASE_URL)

const ReservedTypes = () => {
  const [dataShow, setDataShow] = useState<any>([])
  const [dataTo, setDataTo] = useState<number>(20)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [showForm, setShowForm] = useState<boolean>(false)
  const [newEditItem, setNewEditItem] = useState<ReservedType | undefined>()
  const [countItems, setCountItems] = useState<number>(0);
  const [dataOffset, setDataOffset] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [service] = useState<ReserveTypeService>(ReserveTypeService.Instance);
  const [baseEndpoint, setBaseEndpoint] = useState<string>('/reserved-types'); 
  const [reservedTypesSettingsEndpoint, setReservedTypesEndpoint] = useState<string>('');
  const [loadingReservedType, errorReservedType, dataReservedType]= useData<GridDTO<ReservedType>>(baseEndpoint != '' ? reservedTypesSettingsEndpoint : '/reserved-types', 'GET')

  const [selectedDescription, setSelectedDescription] = useState<string | undefined>('');
  const [selectedCode, setSelectedCode] = useState<string | undefined>('');
  const [selectedEstado, setSelectedEstado] = useState<boolean | undefined>(undefined);
  const [selectedType, setSelectedType] = useState<string | undefined>('');
  
  const [sortArg, setSortArg] = useState<string>('');
  const [newSort, setNewSort] = useState<number>(0);
  const [sort] = useState<Map<string, string>>(new Map());
  const [params] = useState<Map<string,string>>(new Map());  
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [errorResponse, setErrorResponse] = useState<string[] | null>(null);
  const [dataResponse, setDataResponse] = useState<string | null>(null);

  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [dataHeader, setDataHeader] = useState<TableHeaderCell[]>([])
  const [descriptionFilter, setDescriptionFilter] = useState<string>();
  const [statusFilter, setStatusFilter] = useState<string>();
  const [typeCombo, setTypeCombo] = useState<CodeDescription[]>([]);


  const fetchData = async ( body?: typeBody) => {
    try{
      setLoadingResponse(true)
      if (isEdit){
        await client.patch(`/reserved-types/${newEditItem?.id}`, body)
        setDataResponse('Modificado con éxito!')
        setErrorResponse(null)
        setIsEdit(false)
        setNewEditItem(undefined)
      }else{
        await client.post('/reserved-types', body)
        setDataResponse('Creado con éxito')
        setErrorResponse(null)
      }
      formatForm()
      setShowForm(false)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setErrorResponse(error.response?.data.message);
      } else if (error instanceof Error) {
        setErrorResponse([error.message]);
      } else {
        setErrorResponse(['Hubo un error en la solicitud']);
      }
    } finally {
      setLoadingResponse(false);
    } 
  }

  useMemo(()=>{
    service.findAllTypes().then((data)=>{
      const dataCombo: CodeDescription[] = [];
      data.forEach((element: string, i: number) => {
        dataCombo.push(new CodeDescription(i, element, element));
      });
      setTypeCombo(dataCombo);
    })
  }, [])

  const formatForm = () => {
    setSelectedCode('')
    setSelectedDescription('')
    setSelectedEstado(undefined)
    setSelectedType(undefined)
  }

  const saveFormInfo = () => {
      // Validar campos del formulario
      if (!selectedCode || !selectedDescription || (selectedEstado == null || undefined) || (selectedType == null || undefined)){
        alert('Se deben completar todos los campos')
      } else {
        const temporalObject: typeBody = {
          description: selectedDescription,
          code: selectedCode,
          status: selectedEstado,
          type: selectedType
        }
        setShowPopup(true);
        fetchData(temporalObject)
    }
  }

  const deleteTemplateConfig = async (id: number) => {
    try{
      setLoadingResponse(true)
        await client.delete(`/reserved-types/${id}`)
        setDataResponse('Eliminado con éxito!')
        setErrorResponse(null)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setErrorResponse(error.response?.data.message);
      } else if (error instanceof Error) {
        setErrorResponse([error.message]);
      } else {
        setErrorResponse(['Hubo un error en la solicitud']);
      }
    } finally {
      setLoadingResponse(false);
    } 
  }

  useEffect(()=> {
    addParam(dataTo, 'limit', params, baseEndpoint, setReservedTypesEndpoint);
  }, [dataTo])

  useEffect(()=> {
      addParam(dataOffset, 'offset', params, baseEndpoint, setReservedTypesEndpoint);
  }, [dataOffset])

  useEffect(()=> {
      setDataOffset(0)
      setDataShow([]);
      addParam(sortArg, 'sort', params, baseEndpoint, setReservedTypesEndpoint);
    
  }, [sortArg])

  useEffect(()=> {
    addParam(true, 'allStatus', params, baseEndpoint, setReservedTypesEndpoint);
  }, [])

  useEffect(() => {
      addParam(descriptionFilter, 'description', params, baseEndpoint, setReservedTypesEndpoint)
    
  }, [descriptionFilter, baseEndpoint])

  useEffect(() => {
      addParam(statusFilter, 'status', params, baseEndpoint, setReservedTypesEndpoint)
  }, [statusFilter, baseEndpoint]) 

  useEffect(() => {
    if (baseEndpoint == '') setBaseEndpoint('/reserved-types');

    if (!errorReservedType && dataReservedType) {
      setCountItems(dataReservedType.count);
      setDataShow(dataReservedType.results.map((r: ReservedType) => {
          return new TableDetailCell({...r, status: r.status ? 'Activa' : 'Inactiva'}, [
            new TableAction({label: 'Editar', action: () => {
              formatForm()
              setNewEditItem(r)
              setIsEdit(true)
              setShowForm(true)
            },
            icon: <span className={classes.editIcon}><EditIcon></EditIcon></span>
            }),
            new TableAction({label: 'Eliminar', action: async () => {
              setShowPopup(true);
              await deleteTemplateConfig(r.id)
            },
            icon: <span>{NewsanIcons.DELETE}</span>,
            }),
          ])
      })

      )

      
    }
  }, [setDataShow, setCountItems, loadingReservedType, dataReservedType, dataTo, dataOffset, reservedTypesSettingsEndpoint])

  useEffect(() => {
    const filterLoadeed = !loadingReservedType && dataReservedType != null &&  dataHeader.length === 0;

    if(filterLoadeed) {
      setDataHeader([
        new TableHeaderCell({ label: 'Descripción', key: 'description', sorteable: true, colStyle: {paddingLeft: '33px'}, filter: new FilterTable({onChange: (newVal)=>{setDescriptionFilter(newVal)}, placeholder: 'Busca aqui...' }) }),
        new TableHeaderCell({ label: 'Tipo', key: 'type', sorteable: true, colStyle: {paddingLeft: '18px'} }),
        new TableHeaderCell({ label: 'Estado', key: 'status', sorteable: true, colStyle: {paddingLeft: '34px'}, filter: new FilterTable({onChange: (newVal)=>{setStatusFilter(newVal)}, type: FilterTableType.COMBO, data: [{id: 0, code: 'false', description: 'Inactiva'}, {id: 1, code: 'true', description: 'Activa'}], useCodeField: true })}),
      ])
    }
  })

  useEffect(()=>{

    if(newEditItem && isEdit){
      // Setear campos del formulario con los valores del item a editar
      setSelectedDescription(newEditItem.description);
      setSelectedCode(newEditItem.code);
      setSelectedEstado(newEditItem.status);
      setSelectedType(newEditItem.type);
    }
  }, [loadingReservedType, newEditItem, isEdit]);

  useEffect(()=>{
    if(newSort > 0) {
      let args = '';
      sort.forEach((value)=>{
        if(args === '') {
          args += value;
        } else {
          args += `,${value}`;
        }
      });
      setSortArg(args);
      setNewSort(0);
    }
  }, [newSort, setSortArg, setNewSort]);

  const onSort = (key: string, order: string)=>{ 
    if(order === ''){ 
      sort.delete(key)
    } else {
      sort.set(key, `${key}:${order}`);
    }
    setNewSort(1);
  };

  function reloadTable(){
    setBaseEndpoint('')
  }


  return (
    <div className={classes.reserveTypesPage}>
      {
        loadingReservedType ?
        <div className='loadingPage'>
        <CircularProgress className='circleProgress'/>
      </div>
        : null}
      <Topbar title={'Tipos de reserva'} subtitle={''} hasReloadIcon reloadPage={reloadTable}/>
      <div className={classes['container-abm-page']}>
       {(
          errorReservedType ?
          <>Ha habido un problema!</>
          :
        <div
        className={
          showForm ? classes['table-templates-container-form-visible'] : classes['table-templates-container']
        }
        >
          <DataTable
            headers={dataHeader}
            rows={dataShow}
            countItems={countItems}
            rowsPerPage={dataTo}
            hasActions={true}
            appendOnScrollToBottom={false}
            onPageSizeChange={(size) => {setDataTo(size); setDataOffset(0); setPage(1);}}
            onPageChange={(page: number)=>{ setPage(page); setDataOffset(page-1) }}
            pageSelected={page}
            pageable={true}
            selectable={false}
            onSortChange={onSort}
            className=' thinner'
          ></DataTable>
        </div>
          ) 
      }
      {showForm ? (
        <div className={classes['abm-container']}>
          <div className={isEdit ? classes['edit-form-container'] : classes['add-form-container']}>
            <span className={classes['title-form']}>
              {NewsanIcons.ADD_STORE}{' '}
              <span className={classes['title-form-text']}>{isEdit ? 'Editar' : 'Agregar'}</span>
            </span>
            <CloseIcon
              className={classes['form-close-button']}
              onClick={() => {
                formatForm()
                setShowForm(false)
                setIsEdit(false)
                setNewEditItem(undefined)
              }}
            ></CloseIcon>
            <span className={classes['setting-attr-title']}>Nombre del Tipo de Reserva*</span>
            <input
              type='text'
              className={classes['setting-attr-input']}
              placeholder='Nombre del Tipo de Reserva'
              defaultValue={selectedDescription}
              onChange={(e) => {
                setSelectedDescription(e.target.value);
              }}
            />

            <span className={classes['setting-attr-title']}>Código del Tipo de Reserva*</span>
            <input
              type='text'
              className={classes['setting-attr-input']}
              placeholder='Código del Tipo de Reserva'
              defaultValue={selectedCode}
              onChange={(e) => {
                setSelectedCode(e.target.value);
              }}
            />

            <span className={classes['setting-attr-title']}>Tipo</span>
            <FormControl className={classes['select-filter']} size='small'>
              <Select
                value={selectedType ?? ''}
                onChange={(e) => {
                  setSelectedType(e.target.value);
                }}
              >
                <MenuItem value={''}>
                  <em className={classes['no-selection']}>Seleccionar</em>
                </MenuItem>
                {
                  typeCombo.map((tc)=>{
                    return (
                    <MenuItem className={classes['select-option']} key={tc.code} value={tc.code}>
                      {tc.description}
                    </MenuItem>)
                  })
                }
              </Select>
            </FormControl>

            <span className={classes['setting-attr-title']}>Estado</span>
            <FormControl className={classes['select-filter']} size='small'>
              <Select
                value={selectedEstado ? 'Activa' : selectedEstado == false ? 'Inactiva': ''}
                onChange={(e) => {
                  if (e.target.value == 'Activa') {
                    setSelectedEstado(true)
                  } else if (e.target.value == 'Inactiva') {
                    setSelectedEstado(false)
                  }
                }}
              >
                <MenuItem value={''}>
                  <em className={classes['no-selection']}>Seleccionar</em>
                </MenuItem>
                <MenuItem className={classes['select-option']} key={'Activa'} value={'Activa'}>
                  Activa
                </MenuItem>
                <MenuItem className={classes['select-option']} key={'Inactiva'} value={'Inactiva'}>
                  Inactiva
                </MenuItem>
              </Select>
            </FormControl>

            <div className={classes['buttons-container']}>
              <Button
                variant='contained'
                className={classes['btn-newsan-cancel']}
                onClick={() => {
                  setShowForm(false)
                  setIsEdit(false)
                  setNewEditItem(undefined)
                  setDataResponse(null)
                  setErrorResponse(null)
                  formatForm()
                }}
              >
                Cancelar
              </Button>
              <Button
                variant='contained'
                onClick={async () => {await saveFormInfo()}}
                className={
                  isEdit ? classes['button-newsan-red'] : classes['button-newsan-grey']
                }
              >
                {isEdit ? 'Guardar' : 'Cargar'}
              </Button>
            </div>
          </div>
        </div>
      ) : (
          <span className={classes['btn-show-form']} onClick={() => setShowForm(!showForm)}>
            {NewsanIcons.ADD_STORE}
          </span>
      )}
      </div>
      {
        showPopup ? (<PopupSuccessError
          open={showPopup}
          onClose={() =>{ 
            setShowPopup(false);
            setIsEdit(false)
            setNewEditItem(undefined)
            setDataResponse(null)
            setBaseEndpoint(errorResponse == null ? '' : baseEndpoint)
            setErrorResponse(null)
          }}
          loading={loadingResponse}
          success={dataResponse !== null}
          successMessage={dataResponse}
          errorMessage={errorResponse}
        />)
      :
      <></>
      }
    </div>
  )
}

export default ReservedTypes
