/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, CircularProgress, Slider, Stack, SvgIcon, Tooltip, Typography, Modal, Button } from '@mui/material'
import Topbar from '../../components/common/topbar/Topbar';
import classes from './SalesRate.module.css'
import NewsanIcons from '../../components/common/Icons';
import NewsanPics from '../../components/common/Images';
import DataTable from '../../components/common/datatable/Datatable';
import { CodeDescription, FilterTable, FilterTableType, TableAction, TableDetailCell, TableHeaderCell, Warehouse } from '../../model/models-module';
import SalesRateService from '../../services/SalesRateService';
import {ReactComponent as CheckboxIcon} from '../../assets/icons/checkbox.svg';
import { GridDTO, SalesRateResultDTO } from '../../model/dto-module';
import ButtonToCopy from '../../components/common/ButtonToCopy';
import WarehouseService from '../../services/WarehouseService';

  const marksHour = [
    {
      value: 1,
      label: '1 Hora',
    },
    {
      value: 24,
      label: '24 Horas',
    },
  ];

  const marksDay = [
    {
      value: 1,
      label: '1 Día',
    },
    {
      value:31,
      label: '31 Días',
    },
  ];


const SalesRate = () => {

  const warehouseServ = WarehouseService.Instance;
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [byHour, setByHour] = useState<boolean>(true);
  const [delayInputTimeoutId, setDelayInputTimeoutId] = useState<NodeJS.Timeout>();
  const [dataShow, setDataShow] = useState<TableDetailCell[]>([]); 
  const [dataHeader, setDataHeader] = useState<TableHeaderCell[]>([]);
  const [intervalValue, setIntervalValue] = useState<number>(1);
  const [countItems, setCountItems] = useState<number>(0);
  const [limit] = useState<number>(100);
  const [offset, setOffset] = useState<number>(0);
  const service: SalesRateService = SalesRateService.Instance;
  const [filters, setFilters] = useState<any>();
  const [productCode, setProductCode] = useState<any>();
  const [productDescription, setProductDescription] = useState<any>();
  const [warehouseCode, setWarehouseCode] = useState<any>();
  const [sortArg, setSortArg] = useState<string>('');
  const [sort] = useState<Map<string, string>>(new Map());
  const [newSort, setNewSort] = useState<number>(0);
  const [warehouses, setWarehouses] = useState<GridDTO<Warehouse>>();
  const [openHelp, setOpenHelp] = useState(false);
  const [headerActions, setHeaderActions] = useState<TableAction[]>(); 
  

  const getDataByHour = useCallback(async ({limit, offset, intervalHours, warehouseCode, productDescription, productCode, sortArg}: any): Promise<GridDTO<SalesRateResultDTO>> =>{
    return  service.salesRateByHour({limit, offset, intervalHours, warehouseCode, productDescription, productCode, sort: sortArg});
  }, [service]);

  const getDataByDay = useCallback(async ({limit, offset, intervalDays, warehouseCode, productDescription, productCode, sortArg}: any): Promise<GridDTO<SalesRateResultDTO>> =>{
    return  service.salesRateByDay({limit, offset, intervalDays, warehouseCode, productDescription, productCode, sort: sortArg});
  }, [service]);



  const updateFilterObject = useCallback((name:string, value: any)=>{
    if((filters && filters[name] != value) || filters == null) {
      const filter: any = {};
      filter[name] = value;
      const newFilters = Object.assign({}, filters, filter);
      setFilters(newFilters);
    }
  }, [filters]);

  useEffect(() => {
    if(productCode || productDescription || warehouseCode || intervalValue) {
      const auxF: any = {productCode , productDescription , warehouseCode};
      if(byHour) {
        auxF['intervalHours'] = intervalValue;
      } else {
        auxF['intervalDays'] = intervalValue;
      }
      setFilters(auxF);
    } else {
      setFilters({});
    }
  }, [productCode, productDescription, warehouseCode, intervalValue]);

  useMemo(()=>{
    warehouseServ.findAll().then((data)=>{
      setWarehouses(data);
    });
  }, [setWarehouses])

  const setHeaders = useCallback((warehouse: Warehouse[])=>{
    const dataWarehouses = warehouse.map(s=>new CodeDescription(s.id, s.code, s.description));
    setDataHeader([
      new TableHeaderCell({label: 'Almacén', key: 'warehouseCode', sorteable: true, filter: new FilterTable({onChange: (v)=>{setWarehouseCode(v)}, type: FilterTableType.MULTIPLE, useCodeField:true, data: dataWarehouses}), colStyle: {maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',}}),
      new TableHeaderCell({label: 'Descripción', key: 'productDescription', sorteable: true, filter: new FilterTable({onChange: (v)=>{setProductDescription(v)}, type: FilterTableType.TEXT}), colStyle: {minWidth: '225px'}}),
      new TableHeaderCell({label: 'SKU', key: 'productCode', sorteable: true, filter: new FilterTable({onChange: (v)=>{setProductCode(v);}, type: FilterTableType.TEXT})}),
      new TableHeaderCell({label: 'Total vendidos', key: 'totalSales', sorteable: false,  align: 'center', colStyle: {textAlign: 'center', position: 'relative', left: 10}}),
      new TableHeaderCell({label: 'Tasa de venta', key: 'hourlySalesRate', sorteable: false, align: 'center', colStyle: {textAlign: 'center', position: 'relative', left: 10}}),
      new TableHeaderCell({label: 'Reserva', key: 'stockReserved', sorteable: false, align: 'center', colStyle: {textAlign: 'center', position: 'relative', left: 10}}),
      new TableHeaderCell({label: 'Tiempo para quiebre', key: 'hoursForMinimum', sorteable: true, headerStyle: {width: '200px'}, colStyle: {textAlign: 'center'}}),
    ]); 
  }, [setDataHeader, setWarehouseCode]);

  const mapResponse = useCallback((resp: GridDTO<SalesRateResultDTO>, refreshHeader: boolean = false)=>{
    if(refreshHeader && warehouses!) {
      setHeaders(warehouses.results!);
    }
    console.warn(resp);
    if(resp) {
      setCountItems(resp.count);
      setDataShow(resp.results.map((v)=>new TableDetailCell(
          v, 
          undefined,
          new Map<string, React.ReactNode>([
            ['warehouseCode',
              <div key={v.warehouseCode}>
                <Tooltip title={v.warehouseCode!.length > 13 ? v.warehouseCode : null}>
                  <Typography fontSize={'0.875rem'} noWrap
                    sx={{
                      maxWidth: 190,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      cursor: v.warehouseCode!.length > 13 ? 'pointer' : 'auto',
                    }}>
                    {v.warehouseCode}
                  </Typography>
                </Tooltip>
              </div>
            ],
            ['productCode',
            <div key={v.productCode} className={classes['textWithCopy']}>
              <Typography fontSize={'0.875rem'} style={{paddingRight: '40px'}}>{v.productCode}</Typography>
              {v.productCode != undefined ? <ButtonToCopy text={v.productCode} style={{position: 'absolute', right: '0px'}}></ButtonToCopy>: null}
            </div>
            ],
            ['productDescription',
              <div key={v.productCode} className={classes['textWithCopy']}>
                <Tooltip title={v.productDescription!.length > 30 ? v.productDescription : null}>
                  <Typography fontSize={'0.875rem'} noWrap
                    sx={{
                      maxWidth: 290,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      cursor: v.productDescription!.length > 30 ? 'pointer' : 'auto',
                    }}>
                    {v.productDescription}
                  </Typography>
                </Tooltip>
                {v.productDescription != undefined ? <ButtonToCopy text={v.productDescription} style={{position: 'absolute', right: '0px'}}></ButtonToCopy>: null}
              </div>
            ],
            ['hoursForMinimum',
              <div key={v.hoursForMinimum}>
                <Typography fontSize={'0.875rem'} className={v.hoursForMinimum! > 1 ? classes.hourGreen : classes.hourRed}>{v.hoursForMinimum}</Typography>
              </div>
            ]
          ])
        )));
    } else {
      setCountItems(0);
      setDataShow([]);          
    }

  }, [dataHeader, warehouses, setHeaders]);

  useEffect(()=>{
    if(filters) {
      setShowSpinner(true);
      setOffset(0);
      if(byHour) {
        getDataByHour(Object.assign({}, {limit, offset: 0, sortArg}, filters)).then((resp)=>{
          console.warn(resp);
          mapResponse(resp);
        }).finally(()=>{
          setShowSpinner(false);
        });
      } else {
        getDataByDay(Object.assign({}, {limit, offset: 0, sortArg}, filters)).then((resp)=>{
          console.warn(resp);
          mapResponse(resp);
        }).finally(()=>{
          setShowSpinner(false);
        });
      }
    }

  }, [byHour, filters, sortArg, getDataByHour, setDataShow, setShowSpinner, setOffset, mapResponse]);

  const reloadTable = useCallback(()=>{
    setShowSpinner(true);
    const auxF:any = filters;
    if(byHour) {
      getDataByHour({limit, offset, intervalValue, ...auxF}).then((resp)=>{
        mapResponse(resp, true);
      }).finally(()=>{
        setShowSpinner(false);
      });
    } else {
      getDataByDay({limit, offset, intervalValue, ...auxF}).then((resp)=>{
        mapResponse(resp, false);
      }).finally(()=>{
        setShowSpinner(false);
      });
    }
  }, [mapResponse, setShowSpinner, getDataByHour, limit, offset, filters]);

  useMemo(()=>{

    if(!initialized && warehouses != null && warehouses != undefined) {
      setHeaders(warehouses.results);
      setInitialized(true);
    }

  }, [initialized, warehouses, setHeaders]);

  useEffect(()=>{
    if(newSort > 0) {

      let args = '';
      sort.forEach((value)=>{
        if(args === '') {
          args += value;
        } else {
          args += `,${value}`;
        }
      });
      setSortArg(args);
      setNewSort(0);
    }
  }, [newSort, setSortArg, setNewSort]);

  const onSort = (key: string, order: string)=>{ 
    if(order === ''){ 
      sort.delete(key)
    } else {
      sort.set(key, `${key}:${order}`);
    }
    setNewSort(1);
  };

  useMemo(()=>{
    reloadTable();
  }, []);

  useMemo(()=>{
    const helpAction = new TableAction({label:'Ayuda', action: () => {
          setOpenHelp(true)
          }, icon: NewsanIcons.HELP,  cssClass: classes.helpIcon});

    setHeaderActions([helpAction]);
  }, [])

  const onSelectDay = useCallback(()=>{setFilters(null); setIntervalValue(1); setOffset(0); setByHour(false); reloadTable();},[]);
  const onSelectHour = useCallback(()=>{setFilters(null);  setIntervalValue(1); setOffset(0); setByHour(true); reloadTable();},[]);

  return <>
  
    {
    showSpinner ?
      <div className='loadingPage'>
        <CircularProgress className='circleProgress'/>
      </div>
      : null
    }
  <div className={classes.salesRatePage}>
    <Topbar title='Tasa de venta' hasReloadIcon reloadPage={reloadTable} />

    <Modal
        open={openHelp}
        onClose={()=>{setOpenHelp(false)}}
        aria-labelledby="help-modal-title"
        aria-describedby="help-modal-description"
      >
        <div className={classes['helpModal']}>
          <Typography id="help-modal-title" variant="h6" component="h2">
            <strong>Tasa de venta</strong>
          </Typography>
          <Typography id="help-modal-description" sx={{ mt: 2 }}>
            <strong>Filtrar : </strong><br/>
            <dd><strong>Por Día:</strong> Permite seleccionar un intervalo de tiempo basado en cantidad de días.<br/></dd>
            <dd><strong>Por Hora:</strong> Permite seleccionar un intervalo de tiempo basado en cantidad de horas.<br/><br/></dd>
            <strong>Intervalo:</strong> <>{'Define la cantidad de días u horas que se utilizarán para calcular y completar la grilla. Según el filtro seleccionado, puedes elegir un intervalo de 1 a 31 días si has optado por "Día", o de 1 a 24 horas si has seleccionado "Hora".'}</><br/><br/>
            <strong>Total vendidos:</strong> Es la cantidad total de ventas para cada combinación única de SKU y Almacén. Sumarizando los centros de distribución.<br/><br/>
            <strong>Tasa de venta:</strong> Es el ritmo de ventas, calculada como el total de ventas dividida por la cantidad de días u horas del intervalo, dependiendo del filtro seleccionado.<br/><br/>
            <strong>Reserva:</strong> Es la cantidad de stock reservado actual.<br/><br/>
            <strong>Tiempo para quiebre:</strong> Es la cantidad de días u horas que restan para consumir la reserva completamente.<br/>
            <dd><strong>Color verde:</strong><>Indica que la reserva cubre más de 1 hora o 1 día en base a la tasa de venta y el filtro seleccionado.</><br/></dd>
            <dd><strong>Color rojo:</strong> El stock disponible es inferior a 1 hora en base a la tasa de venta.<br/><br/></dd>
          </Typography>
          <Button onClick={()=>setOpenHelp(false)}>Cerrar</Button>
        </div>
      </Modal>

    <div className={classes['assign-stock-header-container']}>
      
      <span className={classes.staticCardRate}>
        <span className={classes['card-title']}>Filtrar</span>
        <div className={classes.checksContainer}>
          <span onClick={onSelectDay}>
            <SvgIcon
              className={ classes.checkboxOpt + (!byHour ? ` ${classes.checked}` : '')} component={CheckboxIcon} viewBox="0 0 18 22" />
              <span>Por día</span>
          </span>
          <span onClick={onSelectHour}>
              <SvgIcon
              className={ classes.checkboxOpt + (byHour ? ` ${classes.checked}` : '')} component={CheckboxIcon} viewBox="0 0 18 22" />
              <span>Por hora</span>
          </span>

        </div>

      </span>
      <span className={classes.staticCardRate + ' nwsn-scroll ' + classes['info-card-interval']}>
        <span className={classes['card-title']}>
          Intervalo
        </span>

        <Box>
          <Stack spacing={0} direction="row" sx={{ mb: 1 }} alignItems="center">
            {
              byHour ?
              <Slider
                className={classes.sliderCss}
                size="small"
                value={intervalValue}
                onChange={(e:any)=>{
                  setIntervalValue(e.target.value);
                  clearTimeout(delayInputTimeoutId);
                  const timeout = setTimeout(() => {
                    updateFilterObject('intervalHours', e.target.value);
                  },  500);
                  setDelayInputTimeoutId(timeout);
                  return () => clearTimeout(timeout);
                  }
                }
                aria-label="Small"
                valueLabelDisplay="auto"
                min={1}
                step={1}
                max={24}
                marks={marksHour}
              />
              : 
              <Slider
                className={classes.sliderCss}
                size="small"
                onChange={(e:any)=>{
                  setIntervalValue(e.target.value);
                  clearTimeout(delayInputTimeoutId);
                  const timeout = setTimeout(() => {
                    updateFilterObject('intervalDays', e.target.value);
                  },  500);
                  setDelayInputTimeoutId(timeout);
                  return () => clearTimeout(timeout);
                  }
                }
                aria-label="Small"
                valueLabelDisplay="auto"
                min={1}
                step={1}
                max={31}
                value={intervalValue}
                marks={marksDay}
              />

            }
            <span className={classes.sliderLimit}>|</span>
          </Stack>
        </Box>      

      </span>

        <span className={classes.character}>
          {NewsanPics.SALES_RATE_ANALYTICS}
        </span>
    </div>
      <div className={classes.gridContainer}>
        {
        <DataTable headers={dataHeader ? dataHeader : []}  rows={dataShow} className={classes['assign-stock-table']}
            countItems={countItems}  rowsPerPage={100} hasActions={true} groupActions={true} onSortChange={onSort}
            appendOnScrollToBottom={true} onScrollToBottom={(pageSize: number)=>console.warn(pageSize)} 
            headerGroupActionsIcon={NewsanIcons.PLUS} headerActions={headerActions}
            ></DataTable>
        }
      </div>
    </div>
    </>
}

export default SalesRate
