import { Box, Popover} from '@mui/material';
import { useState } from 'react';
import './CustomAutoCompleteMultiple.css'
import { DropdownOptionsType } from '../autocomplete-input/AutocompleteInput';
import ConfigurableAutocompleteInput from '../autocomplete-input/ConfigurableAutocompleteInput';

type InputRangeProps = {
    users: string[];
    autocompleteProps: AutocompleteInputProps;
}

type AutocompleteInputProps = {
    name: string
    placeholder?: string
    label: string
    handleOptionChange?: (value: DropdownOptionsType | DropdownOptionsType[] | null) => void
    service?: (searchCriteria: string) => Promise<{ data: DropdownOptionsType[] }>
    cantidadCaracteres?: number
    className?: string
}


const CustomAutoCompleteMultiple = ({ users, autocompleteProps }:InputRangeProps)=> {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setValue(users.join(','));
        setUpdateView(updateView+1)
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const [value, setValue] = useState<string>('');
    const [updateView, setUpdateView] = useState<number>(1);

    return   <>
            <div className='input-options-selected' onClick={handleClick}>
            {value || 'Seleccionar'}
            </div>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ p: 2, width: 300 }}>
                        <ConfigurableAutocompleteInput
                            showComponents={false}
                            placeholder={autocompleteProps.placeholder}
                            name={autocompleteProps.name}
                            label={autocompleteProps.label}
                            multiple={true}
                            autoCompleteSx={{
                                '& .MuiAutocomplete-inputRoot':{
                                    paddingRight: '0px !important',
                                    border: 'none'
                                }
                            }}
                            textFieldSx={{
                                '& input': {
                                borderRadius: '10px',
                                backgroundColor: '#F5F6FA',
                                height: '2vw',
                                marginTop: '5px',
                                paddingLeft: '10px',
                                fontFamily: 'Gilroy-Medium',
                                fontSize: '14px',
                                '&:focus-visible': {
                                    outline: 'none',
                                },
                                },
                            }}
                            service={autocompleteProps.service}
                            onChange={autocompleteProps.handleOptionChange}
                            cantidadCaracteres={autocompleteProps.cantidadCaracteres}
                        />
                    </Box>
                </Popover>
    </>;
}


export default CustomAutoCompleteMultiple;